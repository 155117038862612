import { SetStateAction, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { doc, getDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

import "./feedbackrequest.css";
import toast from 'react-hot-toast';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { FeedbackContact } from '../../models/carrier';
import { feebackContactsAIN, feebackContactsLS } from '../../types/carrierContacts';
import { getEmailDomain } from '../../utils/email';
import { Case, FeedbackMessage } from '../../models/Case/case';
import { REQUEST_STATUS } from '../../types/feedbackTypes';

interface FeedbackRequestProps {
  caseUid: string;
  firstName: string; 
  lastName: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
}

const FeedbackRequestForm: React.FC<FeedbackRequestProps> = ({ caseUid, firstName, lastName, setModalOpen }) => {
  const { userData } = useAppSelector(selectAuth);  
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [feedbackContacts, setFeedbackContacts] = useState<FeedbackContact[]>([])
  const [caseData, setCaseData] = useState<Case | null>(null); 
  const [newMessage, setNewMessage] = useState<string>('');

  const { handleSubmit, setValue, register } = useForm<Case>();

  useEffect(() => {
    const fetchfeedbackContacts = (): FeedbackContact[] => {
      const feebackContacts = userData?.exchangePartner === 'LS' ? feebackContactsLS : feebackContactsAIN;
      return feebackContacts.filter(contact => !contact.domainMatch || (contact.domainMatch && getEmailDomain(contact.email) === getEmailDomain(userData?.email)));
    };

    const fetchCaseData = async () => {
      if (caseUid) {
        const caseDocRef = doc(db, "cases", caseUid);
        const caseDoc = await getDoc(caseDocRef);
        if (caseDoc.exists()) {
          var caseData = caseDoc.data();
          Object.keys(caseData).forEach(key => {
            setValue(key as keyof Case, caseData[key as keyof Case]);
          });   

          setCaseData(caseData);
        } else {
          console.log("No such document in cases!");
        }
      }
    };

    setLoading(true);
    const filteredContacts = fetchfeedbackContacts();
    setFeedbackContacts(filteredContacts);
    fetchCaseData();
    setLoading(false);
  }, [setValue, caseUid, userData]);

  const onSubmit = async (caseData: Case) => {
    try {
      if (!caseData.feedbackRequest) {
        console.error("FeedbackRequest is undefined.");
        return;
      }

      if (caseData.feedbackRequest.requestedOf.length === 0) {
        toast.error("Advisor selection is required.");
        return;
      }

      if (newMessage.length === 0) {
        toast.error("Message is required.");
        return;
      }
  
      // Ensure feedbackMessages array exists
      caseData.feedbackRequest.feedbackMessages ||= [];
  
      // Create a new feedback message
      const feedbackMessage: FeedbackMessage = {
        createdDate: Timestamp.now(),
        createdBy: `${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`.trim(),
        message: newMessage,
      };
      caseData.feedbackRequest.feedbackMessages.push(feedbackMessage);
  
      // Determine feedback status
      const isRequestorMessage = userData?.email === caseData.feedbackRequest.requestedOf;
      caseData.feedbackRequest.feedbackStatus = isRequestorMessage ? REQUEST_STATUS.RESPONDED : REQUEST_STATUS.REQUESTED;
  
      // Update Firestore
      const docRef = doc(db, "cases", caseUid);
      await updateDoc(docRef, caseData);
  
      toast.success("Feedback requested successfully!");
      setModalOpen(false);
    } catch (err: any) {
      // Log and display error
      console.error("Error saving data:", err);
      setError(err.message || "Error saving data");
    }
  };
  
  const handleNewMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewMessage(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className='case-container'>
      {!loading &&
        <>
          <div className="feedback-request-container">
            <div className="form-section">
              <h3>
              Feedback Request - { firstName } { lastName }
              </h3>
            </div>
          
            <form onSubmit={handleSubmit(onSubmit)} className="exchange-form">
              { userData?.email !== caseData?.feedbackRequest?.requestedOf &&
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="advisor">Recipient</label>
                    <select id="advisor" {...register("feedbackRequest.requestedOf")}>
                      <option value=""></option>
                      {feedbackContacts.map((contact) => (
                        <option key={contact.email} value={contact.email}>
                          {contact.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              }

              <div className="form-row">
                <div className="form-group-wide">
                  <textarea
                    id="newMessage"
                    value={newMessage}
                    onChange={handleNewMessageChange}
                    placeholder={`Write your message here requesting feeback on the ${ firstName } ${ lastName } case...`}
                  />
                </div>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>
      
          <div className="existing-messages">
            <h6>Message History</h6>
            {caseData?.feedbackRequest?.feedbackMessages && caseData?.feedbackRequest?.feedbackMessages?.length > 0 ? (
              <ul>
                {caseData?.feedbackRequest?.feedbackMessages.map((msg, index) => (
                  <li key={index} className='message-container'>
                    <p>{msg.createdBy || 'Unknown'}: {msg.createdDate?.toDate().toLocaleString()}</p>
                    { msg.message }
                  </li>
                ))}
              </ul>
            ) : (
              <p>No feedback messages yet.</p>
            )}
          </div>
        </>
      }
    </div>
  );
};

export default FeedbackRequestForm;

