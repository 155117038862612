export function getCachedData<T>(cacheKey: string, cacheDuration: number): T | null {
  const cachedData = localStorage.getItem(cacheKey);
  const now = Date.now();
  if (cachedData) {
    try {
      const { timestamp, data } = JSON.parse(cachedData);
      if (now - timestamp < cacheDuration) {
        console.log("Returning cache");
        return data as T;
      }
    } catch (error) {
      console.error("Error parsing cached data:", error);
    }
  }
  return null;
}

export function setCachedData<T>(cacheKey: string, data: T): void {
  const now = Date.now();
  localStorage.setItem(cacheKey, JSON.stringify({ timestamp: now, data }));
}

export function clearCachedData(cacheKey: string): void {
  localStorage.removeItem(cacheKey);
}