export const capitalize = (word: string) => {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export const FormatNumber = (numStr: string) => {
  const num = Number(numStr); // Convert string to number
  if (isNaN(num)) {
    return numStr;
  }
  const formattedNumber = num.toLocaleString();
  return formattedNumber;
};
