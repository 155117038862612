import {
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { fetchInquiriesByCaseUid } from './inquiry-data.service';
import { fetchExchangeRelaysbyCaseUid } from './exchangeRelay-data.service';
import { Case } from '../models/Case/case';
import { Inquiry } from '../models/Case/inquiry';
import { carrierContacts } from '../types/carrierContacts';
import { createLocalDate, formatDate, getShortDate } from '../utils/dateTime';
import { DashboardSearchParams, LineItem } from '../pages/Case/Dashboard/dashboard';


export const fetchDashboardData = async ({
  agentDomainFilter,
  startDate,
  endDate,
  applicantFirstName,
  applicantLastName,
}: DashboardSearchParams): Promise<LineItem[]> => {
  const casesRef = collection(db, 'cases');
  const conditions = [where('agentDomain', '==', agentDomainFilter)];

  if (startDate) {
    const startTimestamp = Timestamp.fromDate(new Date(startDate));
    conditions.push(where('initialShopDate', '>=', startTimestamp));
  }
  if (endDate) {
    const endTimestamp = Timestamp.fromDate(new Date(endDate));
    conditions.push(where('initialShopDate', '<=', endTimestamp));
  }
  if (applicantFirstName) {
    conditions.push(where('firstNameLower', '==', applicantFirstName));
  }
  if (applicantLastName) {
    conditions.push(where('lastNameLower', '==', applicantLastName));
  }

  let casesQuery;
  if (startDate || endDate) {
    casesQuery = query(casesRef, ...conditions, orderBy('initialShopDate', 'desc'));
  } else {
    casesQuery = query(casesRef, ...conditions);
  }

  const querySnapshot = await getDocs(casesQuery);
  const cases: Case[] = querySnapshot.docs.map((doc) => ({
    uid: doc.id,
    ...doc.data(),
  })) as Case[];

  const lineItemsNested: LineItem[][] = await Promise.all(
    cases.map((caseData) => createLineItems(caseData))
  );
  const lineItems: LineItem[] = lineItemsNested.flat().filter(Boolean);
  return lineItems;
};

const createLineItems = async (caseData: Case): Promise<LineItem[]> => {
  const items: LineItem[] = [];
  try {
    let inquiry: Inquiry | null = null;
    if (caseData.uid) {
      const inquiries = await fetchInquiriesByCaseUid(caseData.uid);
      if (inquiries.length > 0) {
        inquiry = inquiries[0];
      }
    }

    const relayRecords = await fetchExchangeRelaysbyCaseUid(caseData.uid ?? '');
    for (const relay of relayRecords) {
      const lineItem: LineItem = {
        caseUid: caseData.uid ?? '',
        relayId: relay.id ?? '',
        applicant: `${caseData.firstName?.trim() || ''} ${caseData.lastName?.trim() || ''}`.trim(),
        applicantFirst: caseData.firstName?.trim() || '',
        applicantLast: caseData.lastName?.trim() || '',
        marketingRep: `${caseData.contact?.firstName?.trim() || ''} ${caseData.contact?.lastName?.trim() || ''}`.trim(),
        caseManager: `${caseData.agentfirstName?.trim() || ''} ${caseData.agentlastName?.trim() || ''}`.trim(),
        agent: `${caseData.agent1?.firstName?.trim() || ''} ${caseData.agent1?.lastName?.trim() || ''}`.trim(),
        targetPremium: inquiry?.targetPremium || '',
        faceAmount: inquiry?.faceAmount || '',
        carrier: '',
        offerDate: relay.offerDate ? getShortDate(createLocalDate(relay.offerDate as any)) : '',
        tentativeOffer: `${relay.tentativeOffer || ''} ${relay.tentativeOfferSubCategory || ''}`.trim(),
        placed:
          caseData.caseStatus === 'Placed' && caseData.placedCarrier === relay.carrierCode
            ? 'Yes'
            : '',
        shoppedDate: relay.sendDate ? formatDate(relay.sendDate) : '',
        placementMessages: caseData.placementMessages,
      };

      const carrier = carrierContacts.find((contact) => contact.code === relay.carrierCode);
      lineItem.carrier = carrier?.name || '';
      items.push(lineItem);
    }
  } catch (error) {
    console.error('Error processing exchangeRelay for case:', caseData.uid, error);
  }
  return items;
};
