import { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import './dashboard.css';
import { useQuery } from 'react-query';
import { PlacementMessage } from '../../../models/Case/case';
import toast from 'react-hot-toast';
import ExchangeHeader from '../../../components/ExchangeHeader/exchangeheader';
import DashboardFilters from './dashboardFilters';
import CaseList from './caseList';
import { fetchDashboardData } from '../../../services/dashboard-data.service';
import { useSearchParams } from 'react-router-dom';
import { useAgency } from '../../../services/agencyMapping-data.service';

export type LineItem = {
  caseUid: string;
  relayId: string;
  marketingRep?: string;
  caseManager?: string;
  agent?: string;
  applicant: string;
  applicantFirst: string;
  applicantLast: string;
  carrier?: string;
  offerDate?: string;
  targetPremium?: string;
  faceAmount?: string;
  tentativeOffer?: string;
  placed?: string;
  shoppedDate?: string;
  placementMessages?: PlacementMessage[];
};

export interface DashboardSearchParams {
  agentDomainFilter: string;
  startDate?: string;
  endDate?: string;
  applicantFirstName?: string;
  applicantLastName?: string;
}

const Dashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [applicantFirstName, setApplicantFirstName] = useState('');
  const [applicantLastName, setApplicantLastName] = useState('');
  const [exchangePartner, setExchangePartner] = useState<string>('');
  const [agentDomainFilter, setAgentDomainFilter] = useState('');
  const [agency, setAgency] = useState('');
  const [searchParams, setSearchParams] = useState<DashboardSearchParams | null>(null);
  const [selectedName, setSelectedName] = useState("All");

  const { userData } = useAppSelector(selectAuth);
  const [urlSearchParams] = useSearchParams();

  // Only run the query when email is defined
  const email = userData?.email;
  const { data: agencyMapping } = useAgency(email, { enabled: !!email });

  // Set up initial data from the authenticated user.
  useEffect(() => {
    setExchangePartner(userData?.exchangePartner || 'AIN');
    if (userData?.email) {
      const emailDomain = userData.email.substring(userData.email.indexOf('@')) || '';
      setAgentDomainFilter(emailDomain);
      if (userData?.roles?.includes('admin')) {
        setAgentDomainFilter('@advisorsexcel.com');
      }
      const agency = agencyMapping ?? userData?.agency;
      setAgency(agency)
    }
  }, [userData, agencyMapping]);

  useEffect(() => {
    const urlFirstName = urlSearchParams.get('applicantFirstName');
    const urlLastName = urlSearchParams.get('applicantLastName');

    if (urlFirstName) {
      setApplicantFirstName(urlFirstName);
    }
    if (urlLastName) {
      setApplicantLastName(urlLastName);
    }

    // Optionally, trigger the search automatically if both are provided.
    if (urlFirstName && urlLastName) {
      setSearchParams({
        agentDomainFilter,
        applicantFirstName: urlFirstName.trim().toLowerCase(),
        applicantLastName: urlLastName.trim().toLowerCase(),
        startDate,
        endDate,
      });
    }
  }, [urlSearchParams, agentDomainFilter, startDate, endDate]);

  // Use react-query to fetch cases
  const { data: lineItems = [], isLoading, error } = useQuery(
    ['fetchDashboardCases', searchParams],
    () =>
      // searchParams is non-null because enabled ensures that.
      fetchDashboardData({
        agentDomainFilter: searchParams!.agentDomainFilter,
        startDate: searchParams!.startDate,
        endDate: searchParams!.endDate,
        applicantFirstName: searchParams!.applicantFirstName,
        applicantLastName: searchParams!.applicantLastName,
      }),
    {
      enabled: !!searchParams, // Query only runs when searchParams exists.
      staleTime: 1000 * 60 * 120, //cache for 2 hours
    }
  );

  // When the Search button is clicked, update the searchParams state.
  const handleSearch = () => {
    // Count the number of non‑empty filter inputs
    const filtersProvided = [
      startDate.trim(),
      endDate.trim(),
      applicantFirstName.trim(),
      applicantLastName.trim()
    ].filter((value) => value !== '');

    if (filtersProvided.length < 2) {
      toast.error('Please fill out at least two filter inputs.');
      return;
    }

    setSearchParams({ 
      agentDomainFilter, 
      startDate, 
      endDate,
      applicantFirstName: applicantFirstName.trim().toLowerCase(),
      applicantLastName: applicantLastName.trim().toLowerCase(),
     });
  };

  // Compute a distinct list of names from Marketing Rep, Case Manager, and Agent fields.
  const distinctNames: string[] = useMemo(() => {
    const namesSet = new Set<string>();
    lineItems.forEach((item: any) => {
      if (item.marketingRep) namesSet.add(item.marketingRep);
      if (item.caseManager) namesSet.add(item.caseManager);
      if (item.agent) namesSet.add(item.agent);
    });
    return Array.from(namesSet).sort();
  }, [lineItems]);

  if (error) return <p>Error: Unable to fetch records.</p>;

  return (
    <div className="dashboard-container">
      <div className="oq-exchange-partner-header">
        <ExchangeHeader exchangePartner={exchangePartner} />
      </div>

      <div className="offer-logo-section">
        <h4>{agency} Dashboard</h4>
      </div>

      <div className="report-logo-section">
        <h4>{agency} Dashboard</h4>
      </div>

      <DashboardFilters
        startDate={startDate}
        endDate={endDate}
        applicantFirstName={applicantFirstName}
        applicantLastName={applicantLastName}
        distinctNames={distinctNames}
        selectedName={selectedName}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onApplicantFirstNameChange={setApplicantFirstName}
        onApplicantLastNameChange={setApplicantLastName}
        onSelectedNameChange={setSelectedName}
        onSearch={handleSearch}
      />

      {isLoading && <h1>Loading...</h1>}
      {!isLoading && !error && lineItems && (
        <CaseList lineItems={lineItems} selectedName={selectedName} />
      )}
    </div>
  );
};

export default Dashboard;