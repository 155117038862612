import { Bga, Carrier, FeedbackContact } from "../models/carrier";

export const carrierContacts: Carrier[] = [
  { exchangePartner: 'AIN', code: 'symetra', name: 'Symetra', email: 'PremierNewBusiness@Symetra.com', active: true, adminOnly: false },
  { exchangePartner: 'AIN', code: 'securian', name: 'Securian Financial', email: 'LifeQuickQuote@securian.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'prudential', name: 'Prudential', email: 'pruain@prudential.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'protective', name: 'Protective Life', email: 'informals@protective.com', active: true, excludeNY: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'newyorklike', name: 'New York Life', email: 'AMN_NB@NewYorkLife.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'nationwide', name: 'Nationwide', email: 'ISRAEK1@nationwide.com', active: true, adminOnly: false  },
  { exchangePartner: 'AIN', code: 'mutualomaha', name: 'Mutual of Omaha', email: 'AINtrials@mutualofomaha.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'massmutual', name: 'Mass Mutual', email: 'MMSDNewBusiness@MassMutual.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'lincoln', name: 'Lincoln Financial', email: 'AINNB@LFG.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'legalgeneral', name: 'Legal & General', email: 'www.lgaquickquote.com', active: true, adminOnly: true  },
  { exchangePartner: 'AIN', code: 'johnhancock', name: 'John Hancock', email: '', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'corebridge', name: 'Corebridge', email: 'informals@corebridgefinancial.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'paclife', name: 'Pacific Life', email: 'NBSUnderwriting@PacificLife.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'lsw', name: 'LSW', email: 'NBApplicationImages@NationalLife.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'allianz', name: 'Allianz', email: 'lifeinsurance@send.allianzlife.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'equitable', name: 'Equitable', email: 'equitableinformal@equitable.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'principal', name: 'Principal', email: 'ainbusiness@exchange.principal.com', active: true, adminOnly: true },
  { exchangePartner: 'AIN', code: 'pennmutual', name: 'Penn Mutual', email: 'greatlakesregion@pennmutual.com', active: true, adminOnly: true },
  
  { exchangePartner: 'LS', code: 'anico2', name: 'ANICO2', email: '', active: true, adminOnly: true },
]

export const bgas: Bga[] = [
  { code: 'LS', name: 'Lion Street', emailDomain: '@lionstreet.com'},
  { code: 'AIN', name: 'AIN', emailDomain: '@ain.com'},
]

export const feebackContactsAIN: FeedbackContact[] = [
  // { name: 'Ken', email: 'ken@ain.com', domainMatch: false},
  //{ name: 'Will', email: 'will@ain.com', domainMatch: false},
  { name: 'Mike Mccarty', email: 'mike.mccarty@advisorsexcel.com', domainMatch: false},
  //{ name: 'PB', email: 'patrick.blount@apeirongate.com', domainMatch: false},
]

export const feebackContactsLS: FeedbackContact[] = [
  { name: 'name', email: 'email@ls.com', domainMatch: false},
]