import { useState, useEffect, useRef } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { User } from '../../../models/User';

interface UserSelectorProps {
  onSelect: (user: User) => void;
}

const UserSelector: React.FC<UserSelectorProps> = ({ onSelect }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const hasFetched = useRef(false);
  useEffect(() => {
    const fetchUsers = async () => {
      const db = getFirestore();
      try {
        const querySnapshot = await getDocs(collection(db, 'users'));
        const userList: User[] = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            uid: doc.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            agency: data.agency,
            carrierRole: data.carrierRole,
            exchangePartner: data.exchangePartner,
            createdAt: data.createdAt.toDate ? data.createdAt.toDate() : data.createdAt,
            roles: data.roles,
            isCarrier: data.isCarrier,
            carrierCode: data.carrierCode,
            isBga: data.isBga,
          };
        });
        
        setUsers(userList);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchUsers();
    }
  }, []);

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const uid = e.target.value;
    setSelectedUserId(uid);
    const user = users.find((u) => u.uid === uid);
    if (user) {
      onSelect(user);
    }
  };

  if (loading) {
    return <div>Loading users...</div>;
  }

  return (
    <div>
      <select value={selectedUserId} onChange={handleSelectChange}>
        <option value="">-- Select a User --</option>
        {users.map((user) => (
          <option key={user.uid} value={user.uid}>
            {user.firstName} {user.lastName} ({user.email})
          </option>
        ))}
      </select>
    </div>
  );
};

export default UserSelector;
