import { useEffect, useState } from 'react';
import { User } from '../../../models/User';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { PlacementMessage } from '../../../models/Case/case';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { useUsersByEmailDomain } from '../../../services/user.service';
import { queryClient } from '../../../utils/queryClient';

interface NotesSectionProps {
  caseUid: string;
  placementMessages?: PlacementMessage[];
}

const NotesSection: React.FC<NotesSectionProps> = ({ caseUid, placementMessages }) => {

  const [showNotes, setShowNotes] = useState(false);
  const [note, setNote] = useState('');
  const [users, setUsers] = useState<User[]>([])
  const [selectedUser, setSelectedUser] = useState('');
  const [messages, setMessages] = useState<PlacementMessage[]>([]);

  const { userData } = useAppSelector(selectAuth);

  // Only run the query when email is defined
  const email = userData?.roles?.includes('admin') ?  "test@advisorsexcel.com" : userData?.email;
  const { data: usersByEmail } = useUsersByEmailDomain(email, { enabled: !!email });

  useEffect(() => {
    if(placementMessages) {
      setMessages(placementMessages);
    }
    setUsers(usersByEmail ?? [])
  }, [placementMessages, usersByEmail]);

  const toggleNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (note.length === 0) {
      return;
    }

    const recipient = users.find(i => i.email === selectedUser);
    
    const newMessage: PlacementMessage = {
      createdDate: Timestamp.now(),
      createdBy: `${userData?.firstName || ''} ${userData?.lastName || ''}`.trim(),
      message: note,
      ...(recipient ? { recipientEmail: `${recipient.email || ''}`.trim() } : {}),
      ...(recipient ? { recipient: `${recipient.firstName || ''} ${recipient.lastName || ''}`.trim() } : {})
    };
    
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    try {
      const docRef = doc(db, "cases", caseUid);
      await updateDoc(docRef, { placementMessages: updatedMessages });
      //clear cache
      queryClient.invalidateQueries(['fetchDashboardCases']);
    } catch (error) {
      console.error("Error updating messages:", error);
      // revert state if the update fails:
      setMessages(messages);
    }
    
    // Clear the form fields
    setNote('');
    setSelectedUser('');
  };

  function formatCreatedDate(maybeTimestamp?: Timestamp | { seconds: number; nanoseconds: number }): string {
    if (!maybeTimestamp) return '';
    
    // If it's already a Firestore Timestamp instance
    if (maybeTimestamp instanceof Timestamp) {
      return maybeTimestamp.toDate().toLocaleString();
    }
  
    // If it's a plain object with `seconds` and `nanoseconds`
    if ('seconds' in maybeTimestamp && 'nanoseconds' in maybeTimestamp) {
      const realTs = new Timestamp(maybeTimestamp.seconds, maybeTimestamp.nanoseconds);
      return realTs.toDate().toLocaleString();
    }
  
    return '';
  }

  return (
    <>
      <h6 onClick={toggleNotes} className='notes-toggle' style={{ cursor: 'pointer', userSelect: 'none' }}>
        Notes {showNotes ? '▲' : '▼'}
      </h6>

      {showNotes &&
        <div className="notes-section">
          {/* Form for note input, submit button, and user drop down */}
          <form onSubmit={handleSubmit} className="notes-form">
            <div className='notes-container'>
              <div className='notes-input'>
                <textarea placeholder="Enter your note here..." value={note} onChange={(e) => setNote(e.target.value)} />
              </div>
              <div className='notes-submit'>
                <div>
                  <label htmlFor="userSelect">Send Notification (optional):</label>
                  <select id="userSelect" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                    <option value=""></option>
                    {users.map((user) => (
                      <option key={user.uid} value={user.email}>
                        {user.firstName} {user.lastName}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button type="submit">Submit Note</button>
                </div>
              </div>
            </div>
          </form>

          { messages && messages.length > 0 &&
            <div className="placement-messages">
              <h6>History</h6>
              <ul>
                {messages.map((msg, index) => (
                  <li key={index} className='message-container'>
                    <p>{msg.createdBy || 'Unknown'}: {formatCreatedDate(msg.createdDate)}</p>
                    {msg.recipient && <strong>{msg.recipient}:</strong>} { msg.message }
                  </li>
                ))}
              </ul>
            </div>
          } 
        </div>
      } 
    </>
  );
};

export default NotesSection;
