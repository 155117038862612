import React, { useEffect, useState } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import toast from 'react-hot-toast';
import { ExchangeRelay } from '../../models/exchangeRelay';

interface EditableNoteProps {
  relayId: string;
}

const EditableNote: React.FC<EditableNoteProps> = ({ relayId }) => {
  const [note, setNote] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'exchangeRelay', relayId)
      const relaySnapshot = await getDoc(docRef);
      if (relaySnapshot.exists()) {
        const exchangeRelay = relaySnapshot.data() as ExchangeRelay;
        setNote(exchangeRelay.underwriterNote!);
      }
    }

    fetchData();
  }, [relayId]);

  const handleSave = async () => {
    try {
      // Update the note in Firestore
      const docRef = doc(db, 'exchangeRelay', relayId);
      await updateDoc(docRef, { underwriterNote: note });
      toast.success('Note updated successfully');
    } catch (error) {
      toast.error('Failed to update note');
    } finally {
      setEditing(false);
    }
  };

  return (
    <div className='internal-notes'>
      {editing ? (
        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          onBlur={handleSave} // Or add a button to explicitly save changes
          autoFocus
        />
      ) : (
        <div onClick={() => setEditing(true)} style={{ color: note ? 'inherit' : 'grey' }}>
          {note || 'Click to add note...'}
        </div>
      )}
    </div>
  );
};

export default EditableNote;
