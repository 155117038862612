import { useEffect, useState } from 'react';
import { ExchangeRelay } from '../../../models/exchangeRelay';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';

import { Case } from '../../../models/Case/case';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { fetchInquiriesByCaseUid } from '../../../services/inquiry-data.service';
import { carrierContacts } from '../../../types/carrierContacts';
import "./offerdetailreport.css";
import { Inquiry } from '../../../models/Case/inquiry';
import ExchangeHeader from '../../../components/ExchangeHeader/exchangeheader';
import { FormatNumber } from '../../../utils/stringUtils';
import OfferDetails from './offerdetails';

const OfferDetailReport = () => {
  const { uid } = useParams<{ uid: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [caseData, setCaseData] = useState<Case | null>(null);
  const [inquiry, setInquiry] = useState<Inquiry | null>(null);
  const [exchangeRelays, setExchangeRelays] = useState<ExchangeRelay[]>([]);  

  const { userData } = useAppSelector(selectAuth);

  useEffect(() => {
    const fetchCaseData = async () => {
      if (!uid) return;
  
      try {
        // Fetch case data
        const caseDocRef = doc(db, "cases", uid);
        const caseDoc = await getDoc(caseDocRef);
  
        if (!caseDoc.exists()) {
          setError("No such document in cases!");
          return;
        }
  
        const caseData = caseDoc.data();
        setCaseData(caseData);
  
        // Fetch inquiries related to the case
        const inquiries = await fetchInquiriesByCaseUid(uid);
        if (inquiries?.length > 0) {
          setInquiry(inquiries[0]);
        }
  
        // Fetch exchangeRelays related to the case
        const relayQuery = query(collection(db, "exchangeRelay"), where("caseUid", "==", uid));
        const snapshot = await getDocs(relayQuery);
        const relays = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ExchangeRelay));
        setExchangeRelays(relays);
      } catch (error) {
        setError("Error fetching case data");
        console.error("Error fetching case data:", error);
      }
    };

    fetchCaseData().finally(() => setLoading(false));
  }, [uid, userData]);

  const displayCarrier = (carrierCode: string | undefined) => {
    return userData?.carrierCode === carrierCode || !userData?.isCarrier;
  };

  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className="offer-detail-report-container">
      <div className="offer-detail-logo-section">
        <ExchangeHeader exchangePartner={userData?.exchangePartner || ''} />
      </div>

      {loading && <h1>Loading...</h1>}
      {error && 
        <div className="error-message">
          <p>Error: Unable to load the document. Please try again later or contact support@apeirongate.com</p>
        </div>
      }

      <div className='applicant-header'>
        {caseData && <><span className="applicant-header-last-name">{caseData.lastName}</span>, {caseData.firstName}</> }
      </div>

      {inquiry && (
        <div className="case-info-row">
          <div className="case-info-item">
            <span className="case-info-value">${FormatNumber(inquiry.targetPremium!)}</span>
            <span className="case-info-label">TARGET PREMIUM</span>
          </div>
          <div className="case-info-item">
            <span className="case-info-value">01/31/25</span>
            <span className="case-info-label">SHOPPED DATE</span>
          </div>
          <div className="case-info-item">
            <span className="case-info-value">{caseData?.offeredRelayIds?.length}</span>
            <span className="case-info-label">OFFERS RECEIVED</span>
          </div>
          <div className="case-info-item">
            <span className="case-info-value">${FormatNumber(inquiry.faceAmount!)}</span>
            <span className="case-info-label">FACE</span>
          </div>
          <div className="case-info-item">
            <span className="case-info-value">{caseData?.relayIds?.length}</span>
            <span className="case-info-label">CARRIERS SHOPPED</span>
          </div>
        </div>
      )}

      { !loading && !error &&
        <table className="offers-table">
          <colgroup>
            <col style={{ width: "25%" }} />
            <col style={{ width: "25%" }} />
            <col style={{ width: "50%" }} />
          </colgroup>
          <thead>
            <tr>
              <th>CARRIER</th>
              <th>TENTATIVE OFFER</th>
              <th>OFFER EXPLANATION</th>
            </tr>
          </thead>
          <tbody>
            {              
              exchangeRelays.map((relay, index) => {
                const carrier = carrierContacts.find((r) => r.code === relay.carrierCode);
                return(
                  <>
                    <tr>
                      <td>
                        <div className="carrier-name">
                          {displayCarrier(relay.carrierCode) ? carrier?.name.toUpperCase() : '*****' }
                        </div>
                        { relay.carrierId && displayCarrier(relay.carrierCode) &&
                          <div className="sub-info">
                            {relay.carrierId}
                          </div>
                        }
                      </td>
                      <td>
                        <div className="offer-title">{relay.tentativeOffer}</div>
                        <div className="sub-info">{relay.tentativeOfferSubCategory}</div>
                      </td>
                      <td>
                        <div className="offer-explanation">
                          <span dangerouslySetInnerHTML={{ __html: relay.offerExplanation ? relay.offerExplanation.replace(/\n/g, "<br />") : "Offer explanation not provided.", }} />
                        </div>
                      </td>
                    </tr>
                    <tr className="offer-table-details-row">
                      <td colSpan={3}>
                        <OfferDetails detailsSubject={relay.offerDetails && displayCarrier(relay.carrierCode) ? relay.offerDetails.replace(/\n/g, "<br />") : ""} />
                      </td>
                    </tr>
                  </>
                );
              })
            }
            <tr className="offer-table-last-row">
              <td colSpan={3}></td>
            </tr>
          </tbody>
        </table>
      }
    </div>
  );
};

export default OfferDetailReport;

