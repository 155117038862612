import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useDropzone } from 'react-dropzone';

import "./caseupload.css";
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Utilitity/Spinner';
import FileList from '../../../components/HippaUpload/filelist';
import { uploadExchangeFile } from '../../../services/file-upload.service';

interface FormData {
  firstName: string;
  lastName: string;
  dob: string;
  recordSource: string;
  files: File[];
}

type FileEntry = {
  originalFileName: string;
  fileName: string;
};

const CaseUpload: React.FC = () => {  
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);

  // Initialize state with form data
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    dob: '',
    recordSource: '',
    files: [],
  });
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  
  const isSubmitDisabled = !isCheckboxChecked || formData.files.length === 0;

  // Handle input changes
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, });
    console.log(name, value)
  };

  // Set up the dropzone for file uploads
  const onDrop = (acceptedFiles: File[]) => {
    setFormData({
      ...formData,
      files: [...formData.files, ...acceptedFiles],
    });
  };

  // Handle file upload and submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const submissionData = await buildSubmissionData();
    if (!submissionData) return;  // Exit if an error occurs in file upload
    await publishCase(submissionData);
    setIsLoading(false);
  };

  // Create and populate submission data object
  const buildSubmissionData = async (): Promise<{ agentEmail: string; firstName: string; lastName: string; dob: string; files: FileEntry[] } | null> => {
    const submissionData = {
      carrierCode: "X",
      agentEmail: userData?.email || "",
      firstName: formData.firstName,
      lastName: formData.lastName,
      recordSource: formData.recordSource,
      dob: formData.dob,
      files: []
    };

    const fileUploadPromises = formData.files.map((file) => uploadFile(file, submissionData));
    await Promise.all(fileUploadPromises);
    return submissionData;
  };

  // Upload each file
  const uploadFile = async (file: File, submissionData: any) => {
    //const bucketName = 'exchange-uploads';
    // const data = new FormData();
    // data.append('file', file);

    try {
      // const response = await fetch(`https://us-central1-apeirongateportal.cloudfunctions.net/CS-uploadToFirebaseStorage/upload?bucketName=${bucketName}`, 
      // {
      //   method: 'POST',
      //   body: data,
      // });
      // const result = await response.json();
      const result = await uploadExchangeFile(file);
      submissionData.files.push({ originalFileName: file.name, fileName: result.fileName });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('File upload failed. Please try again.');
      setIsLoading(false);
      return null;
    }
  };

  // Publish case data after all files are uploaded
  const publishCase = async (submissionData: any) => {
    try {
      const response = await fetch('https://us-central1-apeirongateportal.cloudfunctions.net/Case-requests/upload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit: ${response.statusText}`);
      }

      const result = await response.json();
      console.log(result.message);

      toast.success('Case upload successful! It will appear in Trial Manager after processing.', { duration: 5000, });
      navigate('/casemanager');
    } catch (err) {
      toast.error('An unexpected error occurred. Please check files and try again.', { duration: 5000, });
    }
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = formData.files.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      files: updatedFiles,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className='upload-container'>
      {isLoading && <Spinner message="Processing... Please do not close or refresh the page." />}
      <div className="form-section">
        <h2>Upload Records</h2>
      </div>
      
      <form onSubmit={handleSubmit}  className="upload-form">
        <div className="how-to">
          Use this page to upload APS/EHR records for any new cases you want to shop on the Exchange and follow these guidelines: 
          <ol>
            <li><strong>Each upload should contain ALL records for a single applicant.</strong> Do not upload files for multiple applicants at once. </li>
            <li><strong>Ensure you have all required records for the applicant and securely upload them below.</strong></li>
            <li><strong>Complete the record detail fields below.</strong></li>
          </ol>
          Once uploaded, this applicant will be added to your <strong>Trial Manager</strong> screen within the Exchange and our team will begin the summary process. 
          You can expect these records to be summarized within <strong>1 – 2 business days</strong>. 
        </div>
        <h3>Applicant</h3>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="firstName" className="strong">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName" className="strong">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="dob" className="strong">DOB</label>
            <input
              type="date"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <label className="strong">How did your office receive these records?</label>
        </div>
        <div className="radio-row form-row">
          <label className="radio-group">
            <input type="radio" value="agent" name="recordSource" id="option1" onChange={handleInputChange} required />
            Directly from the Agent
          </label>
          <label className="radio-group">
            <input type="radio" value="recordCompany" name="recordSource" id="option2" onChange={handleInputChange} required />
            Another Record Retrieval Company
          </label>
          <label className="radio-group">
            <input type="radio" value="OtherApplied" name="recordSource" id="option3" onChange={handleInputChange} required />
            Other &amp; Applied For
          </label>
        </div>

        <h3>File Upload</h3>
        <div className="upload-note">
          <strong>IMPORTANT:</strong>&nbsp;
          This applies only to APS/EHR records obtained outside of <strong>Jetstream APS</strong>. If you ordered records through Jetstream APS and checked the box <strong>“Send to Apeiron Gate for Summary,”</strong> then your files will automatically transfer to Apeiron Gate, and no further action is required. 
        </div>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`} >
          <input {...getInputProps({ accept: '.pdf, .tiff, .tif' })} />
          { isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p> }
        </div>

        <FileList files={formData.files} onDelete={(index) => handleFileDelete(index)} />

        <div className="form-row upload-note">
          <input type="checkbox" checked={isCheckboxChecked} onChange={() => setIsCheckboxChecked(!isCheckboxChecked)} />
          I confirm that I have uploaded the HIPAA Compliant Authorization and Disclosure Information for this applicant and that there are no outstanding records for 
          this case.
        </div>
        <div className="form-row upload-note">
          <strong>Terms & Conditions: By checking this box, I acknowledge and accept the Terms and Conditions outlined below.  </strong>
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button"  disabled={isSubmitDisabled}>Submit</button>

        <div className="upload-note">
          <strong>Terms & Conditions</strong><br/>
          By uploading documents onto this site, you agree to comply with applicable Security and Privacy Rules, including HIPAA (PL 104-191), Standards for Privacy of Individually Identifiable Health Information (45 CFR Parts 160 & 164), and the Gramm-Leach-Bliley Act (15 USCS § 6801-6809). If you suspect misuse or mishandling of this information, contact Apeiron Gate and the information provider immediately. Data may be archived, accessed by authorized personnel, and disclosed to third parties, including public authorities, in compliance with applicable laws.    
        </div>
      </form>
    </div>
  );
};

export default CaseUpload;
