import React from 'react';
import ApplicantHeaderTable from './applicantHeaderTable';
import OfferDetailsTable from './offerDetailsTable';
import NotesSection from './dashboardNotes';
import { LineItem } from './dashboard';

interface CaseListProps {
  lineItems: LineItem[];
  selectedName: string;
}

const CaseList: React.FC<CaseListProps> = ({ lineItems, selectedName }) => {
  // Filter line items based on the selected name.
  const filteredLineItems =
    selectedName === 'All'
      ? lineItems
      : lineItems.filter(
          (item) =>
            item.marketingRep === selectedName ||
            item.caseManager === selectedName ||
            item.agent === selectedName
        );

  return (
    <div className="dashboard-wrapper">
      <div className="dashboard-container">
        {filteredLineItems.reduce<JSX.Element[]>((acc, item, index, array) => {
          const isNewApplicant =
            index === 0 || item.applicant !== array[index - 1].applicant;

          if (isNewApplicant) {
            const applicantRows = array.filter(
              (row) => row.applicant === item.applicant
            );

            acc.push(
              <div key={item.applicant} style={{ paddingBottom: '15px' }}>
                <ApplicantHeaderTable
                  applicantLast={item.applicantLast}
                  applicantFirst={item.applicantFirst}
                  marketingRep={item.marketingRep}
                  caseManager={item.caseManager}
                  agent={item.agent}
                  targetPremium={item.targetPremium}
                  faceAmount={item.faceAmount}
                />
                <OfferDetailsTable rows={applicantRows} />
                <NotesSection
                  caseUid={item.caseUid}
                  placementMessages={item.placementMessages}
                />
              </div>
            );
          }
          return acc;
        }, [])}
      </div>
    </div>
  );
};

export default CaseList;
