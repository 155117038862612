import { db } from '../firebaseConfig';
import {
  onSnapshot,
  collection,
  query,
  where,
  limit,
  orderBy,
  Timestamp,
  getDocs
} from 'firebase/firestore';
import { ExchangeRelay } from '../models/exchangeRelay';
import { getCachedData, setCachedData } from './cache.service';

const exchangeRelayRef = collection(db, 'exchangeRelay');

export const fetchExchangeRelaysbyCaseUid = async (caseUid: string): Promise<ExchangeRelay[]> => {
  // Check for cached data
  const CACHE_DURATION = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
  const cacheKey = `fetchExchangeRelaysbyCaseUid_${caseUid}`;
  const cacheddata = getCachedData<ExchangeRelay[]>(cacheKey, CACHE_DURATION);
  if (cacheddata) {
    return cacheddata;
  }

  // Execute the query if no valid cache exists.
  const relayQuery = query(exchangeRelayRef, where("caseUid", "==", caseUid));
  const relaySnapshot = await getDocs(relayQuery);
  const relayRecords: ExchangeRelay[] = relaySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as ExchangeRelay[];

  // Cache the result
  setCachedData(cacheKey, relayRecords);

  return relayRecords;
};


export const carrierCaseManagerFetchRelays = (
  carrierCode: string,
  batchSize: number,
  callback: (relays: ExchangeRelay[]) => void
): (() => void) => {
  // 90 days in milliseconds
  const ninetyDaysAgo = Timestamp.fromDate(new Date(new Date().getTime() - 360 * 24 * 60 * 60 * 1000));
  
  const relaysQuery = query(
    exchangeRelayRef,
    where('carrierCode', '==', carrierCode),
    where('createdDate', '>=', ninetyDaysAgo),
    orderBy('createdDate', 'desc'),
    limit(batchSize)
  );

  // Listen for real-time updates
  const unsubscribe = onSnapshot(relaysQuery, (snapshot) => {
    const recentRelays: ExchangeRelay[] = snapshot.docs.map(doc => ({
      uid: doc.id,
      id: doc.id,
      ...doc.data(),
    })) as ExchangeRelay[];

    // Trigger the callback with the updated data
    callback(recentRelays);
  });

  // Return the unsubscribe function to stop listening
  return unsubscribe;
};
