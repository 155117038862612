export interface Permission {
  value: string;
  description: string;
  firestoreCollection?: string;
}

export const ALL_PERMISSIONS: Permission[] = [
  { value: 'admin', description: 'Admin' },
  { value: 'offerquality', description: 'Offer Quality Report' },
  { value: 'opportunityreport', description: 'Opportunity Report' },
  { value: 'caseOfferEditor', description: 'Trial Manager - Offer Editor' },
  { value: 'underwriterReviewer', description: 'Trial Manager - Underwriter Reviewer', firestoreCollection: 'userPermissions' },
  { value: 'summaryDownload', description: 'Trial Manager - Summary Download', firestoreCollection: 'userPermissions' },
];