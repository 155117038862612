interface DashboardFiltersProps {
  startDate: string;
  endDate: string;
  applicantFirstName: string;
  applicantLastName: string;
  distinctNames: string[];
  selectedName: string;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  onApplicantFirstNameChange: (value: string) => void;
  onApplicantLastNameChange: (value: string) => void;
  onSelectedNameChange: (value: string) => void;
  onSearch: () => void;
}

const DashboardFilters: React.FC<DashboardFiltersProps> = ({
  startDate,
  endDate,
  applicantFirstName,
  applicantLastName,
  distinctNames,
  selectedName,
  onStartDateChange,
  onEndDateChange,
  onApplicantFirstNameChange,
  onApplicantLastNameChange,
  onSelectedNameChange,
  onSearch,
}) => {
  return (
    <div className="form-row dashboard-filter">
      <div className="form-group">
        <label htmlFor="startdate">Start Date</label>
        <input
          id="startdate"
          type="date"
          value={startDate}
          onChange={(e) => onStartDateChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="enddate">End Date</label>
        <input
          id="enddate"
          type="date"
          value={endDate}
          onChange={(e) => onEndDateChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="firstNameFilter">Applicant First Name</label>
        <input
          id="firstNameFilter"
          type="text"
          value={applicantFirstName}
          onChange={(e) => onApplicantFirstNameChange(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastNameFilter">Applicant Last Name</label>
        <input
          id="lastNameFilter"
          type="text"
          value={applicantLastName}
          onChange={(e) => onApplicantLastNameChange(e.target.value)}
        />
      </div>
      {distinctNames.length > 0 && (
        <div className="form-group">
          <label htmlFor="nameFilter">Filter by Name</label>
          <select
            id="nameFilter"
            value={selectedName}
            onChange={(e) => onSelectedNameChange(e.target.value)}
          >
            <option value="All">All</option>
            {distinctNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="form-group filter-button">
        <button className="shopped-button" onClick={onSearch}>
          Search
        </button>
      </div>
    </div>
  );
};

export default DashboardFilters;
