export const uploadExchangeFile = async (file: File) => {
  const bucketName = 'exchange-uploads';
  const data = new FormData();
  data.append('file', file);

  const response = await fetch(`https://us-central1-apeirongateportal.cloudfunctions.net/CS-uploadToFirebaseStorage/upload?bucketName=${bucketName}`, 
  {
    method: 'POST',
    body: data,
  });
  const result = await response.json();
  return result;
};