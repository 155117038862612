import { useAgency } from "../../services/agencyMapping-data.service";

interface SubmittingOrgCellProps {
  agentEmail: string;
  agency?: string;
}

export const SubmittingOrgCell: React.FC<SubmittingOrgCellProps> = ({ agentEmail, agency }) => {
  const { data: agencyData } = useAgency(agentEmail);
  const displayValue = agency || agencyData || "";

  return <span>{displayValue}</span>;
};