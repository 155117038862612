import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";
import { useNavigate } from "react-router";
import { object, string, TypeOf } from 'zod';
import { LoadingButton } from '@mui/lab';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import toast from 'react-hot-toast';
import { Box, Container, Typography } from '@mui/material';
import FormInput from "../../../components/FormInputs/FormInput";
import { LinkItem } from "../../../components/LinkItem";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux-hooks";
import { fetchUserData, selectAuth, setError, setLoading, setUserData, setUserToken } from "../../../redux/slices/authSlice";
import { getFunctions, httpsCallable } from "firebase/functions";


import './login.css';
import { useState } from "react";
import { app } from "../../../firebaseConfig";
import { isExemptEmail } from "../../../utils/mfa";

const loginSchema = object({
  email: string().min(1, "Email address is required").email("Email Address is invalid"),
  password: string().min(1, "Password is required").min(8, "Password must be more than 8 characters").max(32, "Password must be less than 32 characters"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const Login = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const navigate = useNavigate();
  const { handleSubmit } = methods;
  const dispatch = useAppDispatch();
  const {  loading } = useAppSelector(selectAuth);

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState<User>();

  const authenticateUser = async (email: string, password: string) => {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    setUser(user);
    return user;
  };

  const completeLogin = async (user: any) => {
    const accessToken = await user.getIdToken();
    dispatch(setUserToken(accessToken));
    dispatch(fetchUserData(user.uid));
    toast.success("Login successful!");
    navigate("/");
  };

  const sendOtpToUser = async (email: string) => {
    try {
      const functions = getFunctions(app);
      const sendOtp = httpsCallable(functions, "sendOtp");
      await sendOtp({ email });
      setOtpSent(true);
      toast.success("OTP sent to your email.");
    } catch (error) {
      console.error("Failed to send OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
    }
  };

  const verifyOtp = async () => {
    dispatch(setLoading(true));
    try {
      const functions = getFunctions();
      const verifyOtpFunction = httpsCallable(functions, "verifyOtp");
      await verifyOtpFunction({ email, otp });

      if (user) {
        await completeLogin(user);
      }
    } catch (error) {
      console.error("OTP verification failed:", error);
      toast.error("Invalid OTP. Please try again.");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
    dispatch(setUserData(null));
    dispatch(setLoading(true));

    try {
      const user = await authenticateUser(values.email, values.password);
      setEmail(values.email);

      if (isExemptEmail(values.email)) {
        await completeLogin(user);
      } else {
        await sendOtpToUser(values.email);
      }
    } catch (error) {
      console.error("Login error:", error);
      dispatch(setError(error instanceof Error ? error.message : "An unexpected error occurred"));
      toast.error("Login failed. Please check your credentials.");
    } finally {
      dispatch(setLoading(false));
    }
  };


  return (
    <Container maxWidth={false} className="login-container" sx={{
      display: 'flex',
    }}>
      <Box className="login-box"> 
        <FormProvider {...methods}>
          {!otpSent ? (
            <Box component="form" onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="off" className="login-form-box">
              <div className="typography-login-h2">Sign in</div>

              <FormInput name="email" label="Email" type="email" />
              <FormInput name="password" label="Password" type="password" />

              <Typography className="typography-login-links">
                <LinkItem to="/forgotpassword">Forgot Password?</LinkItem>
              </Typography>

              <LoadingButton className="loading-button" variant="contained" fullWidth disableElevation type="submit" loading={loading}>
                Sign in
              </LoadingButton>
            </Box>
          ) : (
            <Box component="form" className="login-form-box">
              <div className="typography-login-h3">Two-Factor Authentication</div>
              <FormInput name="otpcode" label="We've sent you an email. Enter the code below." value={otp} onChange={(e: any) => setOtp(e.target.value)} />
              <LoadingButton className="loading-button" variant="contained" disableElevation onClick={verifyOtp} loading={loading}>
                Continue
              </LoadingButton>
            </Box>
          )}
        </FormProvider>
      </Box>
    </Container>
  );
};

export default Login;