import { useState } from 'react';
import toast from 'react-hot-toast';

import "./userPermissions.css";
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import UserSelector from './userSelector';
import PermissionAssigner from './permissionAssigner';
import { User } from '../../../models/User';

const UserPermissionsPage: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
  };

  const handlePermissionsSave = async (updatedPermissions: string[]) => {
    if (!selectedUser) return;

    const db = getFirestore();
    try {
      const userRef = doc(db, 'users', selectedUser.uid);
      await updateDoc(userRef, { roles: updatedPermissions });
      setSelectedUser({ ...selectedUser, roles: updatedPermissions });

      toast.success(`Permissions updated for: ${selectedUser.firstName} ${selectedUser.lastName}`);
    } catch (error) {
      toast.error(`Error updating permissions: ${error}`)
    }
  };

  return (
    <div className="container user-permissions-container">
      <div className="form-section">
        <h2>User Permissions Manager</h2>
      </div>
      <UserSelector onSelect={handleUserSelect} />
      {selectedUser && (
        <div style={{ marginTop: '2rem' }}>
          <h5>
            Edit Permissions for {selectedUser.firstName} {selectedUser.lastName} {selectedUser.isCarrier ? '(Carrier)' : ''} {selectedUser.isBga ? '(BGA)' : ''}
          </h5>
          <PermissionAssigner
            user={selectedUser}
            onSave={handlePermissionsSave}
          />
        </div>
      )}
    </div>
  );
};

export default UserPermissionsPage;
