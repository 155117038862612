import { useState } from 'react';

interface NotesSectionProps {
  detailsSubject?: string;
}

const OfferDetails: React.FC<NotesSectionProps> = ({ detailsSubject }) => {
  const [showOfferDetails, setShowOfferDetails] = useState(false);

  const toggleNotes = () => {
    setShowOfferDetails(!showOfferDetails);
  };


  return (
    <div className='offer-details-subject'>
      <h6 onClick={toggleNotes}  style={{ cursor: 'pointer', userSelect: 'none' }}>
        {showOfferDetails ? '▲' : '▼'} OFFER DETAILS & SUBJECT TO
      </h6>

      {showOfferDetails &&
        <div className='details-subject'>
          <span dangerouslySetInnerHTML={{ __html: detailsSubject && detailsSubject?.length > 0 ? detailsSubject : "", }} />
        </div>
      } 
    </div>
  );
};

export default OfferDetails;
