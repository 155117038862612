import { collection, query, where, getDocs, DocumentData } from 'firebase/firestore'; 
import { db } from '../firebaseConfig';
import { UserPermission } from '../models/userPermissions';
import { getCachedData, setCachedData } from './cache.service';

const CACHE_DURATION = 4 * 60 * 60 * 1000; // 4 hours in milliseconds

export const fetchPermissions = async (emailDomain: string, permission: string): Promise<UserPermission[]> => {
  // Check if we have a cached value in localStorage
  const cacheKey = `fetchPermissions_${emailDomain}_${permission}`;
  const cacheddata = getCachedData<UserPermission[]>(cacheKey, CACHE_DURATION);
  if (cacheddata) {
    return cacheddata;
  }

  try {
    const permissionCollectionRef = collection(db, 'userPermissions');
    const q = query(
      permissionCollectionRef, 
      where('emailDomain', '==', emailDomain), 
      where('permission', '==', permission)
    );
    const querySnapshot = await getDocs(q);

    const permissions: UserPermission[] = [];
    querySnapshot.forEach((doc: DocumentData) => {
      permissions.push(doc.data() as UserPermission);
    });

    // Cache the result
    setCachedData(cacheKey, permissions);
    
    return permissions;
  } catch (error) {
    console.error('Error fetching permissions:', error);
    throw error;
  }
};
