import { useEffect, useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { doc, DocumentSnapshot, updateDoc } from 'firebase/firestore';

import "./carriercasemanager.css";

import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { Case } from '../../models/Case/case';
import { SummaryStatus } from '../../types/caseTypes';
import OfferDetail from '../../components/OfferDetail/offerdetail';
import { ExchangeRelay } from '../../models/exchangeRelay';
import { CaseManagerSearchRequest } from '../../types/caseSearchRequest';
import { convertmmddyyyyToDate, formatDate } from '../../utils/dateTime';
import { carrierCaseManagerFetchCases } from '../../services/case-data.service';
import { CarrierCaseManagerResults } from '../../models/Case/caseWithRelay';
import { useExchangeRelayAndCases } from '../../services/exchange-case.service';
import { db } from '../../firebaseConfig';
import { capitalize } from '../../utils/stringUtils';

const CarrierCaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);

  // Modal & Selected Case State
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [selectedExchangeRelay, setSelectedExchangeRelay] = useState<ExchangeRelay | null>(null);

  // Data States for Cases & Relays
  const [currentPageData, setCurrentPageData] = useState<Case[]>([]);
  const [currentPageRelays, setCurrentPageRelays] = useState<ExchangeRelay[]>([]);
  const [cachedPages, setCachedPages] = useState<Case[][]>([]);
  const [cachedRelays, setCachedRelays] = useState<ExchangeRelay[][]>([]);
  const [lastRelayDoc, setLastRelayDoc] = useState<DocumentSnapshot | null>(null);
  
  const initialSearch: CaseManagerSearchRequest = {
    caseStatus: "",
    firstName: "",
    lastName: "",
    showOrgCases: false,
    batchSize: 10,
    userEmail: userData?.email || ""
  };
  const [searchFormValues, setSearchFormValues] = useState(initialSearch);
  const [filters, setFilters] = useState(initialSearch);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [batchSize, setBatchSize] = useState(10);

  const { data, isLoading, refetch } = useExchangeRelayAndCases(userData?.carrierCode || '', filters.batchSize, lastRelayDoc);

  const areFiltersDifferent = (a: CaseManagerSearchRequest, b: CaseManagerSearchRequest): boolean => {
    return Object.keys(a).some((key) => a[key as keyof CaseManagerSearchRequest] !== b[key as keyof CaseManagerSearchRequest]);
  };

  const handleSearch = () => {
    if (areFiltersDifferent(searchFormValues, filters) && userData?.carrierCode) {
      setFilters(searchFormValues);
      const unsubscribe = carrierCaseManagerFetchCases(searchFormValues, userData.carrierCode, (fetchedCases: CarrierCaseManagerResults) => {
        setCurrentPageData(fetchedCases.cases);
        setCurrentPageRelays(fetchedCases.exchangeRelays);
        setHasNextPage(false);
      });

      return () => unsubscribe();
    }
  };

  const handleClear = () => {
    setSearchFormValues(initialSearch);
    setFilters(initialSearch);
    handlePageChange(1);
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSearchFormValues({
      ...searchFormValues,
      [name]: value,
    });
  };

  // Initial data load
  const hasFetched = useRef(false);
  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      refetch().then((result: any) => {
        const cases = result?.data?.cases;
        if (cases) {
          setCachedPages([cases]);
          setCachedRelays([result.data.relays]);
          setCurrentPageData(cases);
          setCurrentPageRelays(result.data.relays);
          setHasNextPage(result.data.hasNextPage);
          setLastRelayDoc(result.data.lastVisible);
        }
      });
    }
  }, [refetch])

  useEffect(() => {
    refetch().then((result: any) => {
      const cases = result?.data?.cases;
        if (cases) {
          setCachedPages([cases]);
          setCachedRelays([result.data.relays]);
          setCurrentPageData(cases);
          setCurrentPageRelays(result.data.relays);
          setHasNextPage(result.data.hasNextPage);
          setLastRelayDoc(result.data.lastVisible);
        }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Update current page data when new data comes in
  useEffect(() => {
    if (data && currentPageData.length) {
      // If the data corresponds to the current page, update state.
      if (data.cases[0]?.uid === currentPageData[0]?.uid && data.cases.length === currentPageData.length) {
        setCurrentPageData(data.cases);
        setCurrentPageRelays(data.relays);
      }
    }
  }, [data, currentPageData]);

  // Pagination handling
  const handlePageChange = async (newPage: number) => {
    if (newPage > currentPage) {
      // Next page: fetch if not cached
      if (newPage > cachedPages.length) {
        const result = await refetch();
        if (result?.data?.cases) {
          setHasNextPage(result.data.hasNextPage);
          setCachedPages(prev => [...prev, result.data.cases]);
          setCachedRelays(prev => [...prev, result.data.relays]);
          setCurrentPageData(result.data.cases);
          setCurrentPageRelays(result.data.relays);
          setLastRelayDoc(result.data.lastVisible);
        }
      } else {
        const pageData = cachedPages[newPage - 1] || [];
        setCurrentPageData(pageData);
        setCurrentPageRelays(cachedRelays[newPage - 1] || []);
        setHasNextPage(batchSize === pageData.length);
      }
    } else {
      // Previous page
      const pageData = cachedPages[newPage - 1] || [];
      setCurrentPageData(pageData);
      setCurrentPageRelays(cachedRelays[newPage - 1] || []);
      setHasNextPage(true);
    }
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newBatchSize: number) => {
    setBatchSize(newBatchSize);
    // Update the local search form state
    setSearchFormValues((prev) => ({ ...prev, batchSize: newBatchSize }));
    
    // Reset pagination state
    setCurrentPage(1);
    setCachedPages([]);
    setLastRelayDoc(null);
    
    // Create new filters with the new batchSize
    const newFilters = { ...filters, batchSize: newBatchSize };
    setFilters(newFilters);
  };

  // Modal handling
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedExchangeRelay(null);
  };

  // Opens modal with offer details for a case
  const handleViewOffer = (caseUid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === caseUid);
    const caseData = currentPageData.find(c => c.uid === caseUid);
    if (relay && caseData) {
      setSelectedCase(caseData);
      setSelectedExchangeRelay(relay);
      setModalOpen(true);
    }
  };

  const handleViewOfferDetails = (uid: string) => {
    navigate(`/case/${uid}/offerDetail`);
  };

  // Returns a formatted sendDate from the corresponding relay
  const getSendDate = (uid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === uid);
    return relay?.sendDate ? formatDate(relay.sendDate) : "";
  };

  const getRawSendDate = (uid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === uid);
    return relay?.sendDate ? convertmmddyyyyToDate(relay.sendDate) : null;
  };

  // Determine if an offer is complete for a given case
  const isOfferComplete = (caseUid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === caseUid);
    return !!(relay?.offerDetails || relay?.tentativeOffer || relay?.offerAmount);
  };

  const getRelayUid = (uid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === uid);
    return relay?.id;
  };

  const getClaimedBy = (uid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === uid);
    return relay?.carrierClaimedBy;
  };

  const isCaseClaimed = (caseUid: string) => {
    const relay = currentPageRelays.find(r => r.caseUid === caseUid);
    return relay?.carrierClaimedBy && relay?.carrierClaimedBy?.length > 0;
  }

  const handleClaimCase = async (caseUid: string) => {
    const pageIndex = currentPage - 1;
    const relay = currentPageRelays.find(r => r.caseUid === caseUid);
    const relayIndex = currentPageRelays.findIndex(r => r.caseUid === caseUid);
    if (relayIndex === -1 || !relay?.id) return;

    const updatedClaim = `${capitalize(userData?.firstName ?? '')} ${capitalize(userData?.lastName ?? '')}`;
    
    // Optimistically update the relay
    const updatedRelay = {
      ...currentPageRelays[relayIndex],
      carrierClaimedBy: updatedClaim
    };
    setCurrentPageRelays(prev => {
      const newRelays = [...prev];
      newRelays[relayIndex] = updatedRelay;
      return newRelays;
    });

     // Also update the cachedRelays for the current page
    setCachedRelays(prev => {
      const newCachedRelays = [...prev];
      if (newCachedRelays[pageIndex]) {
        newCachedRelays[pageIndex] = newCachedRelays[pageIndex].map(r =>
          r.caseUid === caseUid ? { ...r, carrierClaimedBy: updatedClaim } : r
        );
      }
      return newCachedRelays;
    });
  
    try {
      const docRef = doc(db, 'exchangeRelay', relay.id);
      await updateDoc(docRef, { carrierClaimedBy: updatedClaim });
    } catch (error) {
      console.error("Error updating document:", error);
      // Optionally, revert the optimistic update on error
    }
  };
  
  // DataTable columns definition
  const columns: any[] = [
    {  
      name: 'Submitting Org',  
      selector: (row: { agency: any; }) => row.agency,
    },
    {  
      name: 'First Name',  
      selector: (row: Case) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: Case) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: Case) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {
      name: 'Created Date',
      selector: (row: { uid: string }) => getRawSendDate(row.uid), // sorting uses Date object
      sortable: true,
      cell: (row: { uid: string }) => getSendDate(row.uid), // display uses formatted string
    },
    {  
      name: 'Summary Status',
      cell: (row: Case & { uid: string }) => (
        row.summaryStatus === SummaryStatus.COMPLETED ? (
          <a href={`/case/${row.uid}/carriersummary/${getRelayUid(row.uid)}/${row.appId}`}>{row.summaryStatus}</a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Offer',
      cell: (row: { uid: string }) => (
        <div className="left-align">
          <button onClick={() => handleViewOffer(row.uid)} 
            className={`inquiry-button ${isOfferComplete(row.uid) ? '' : 'incomplete-status'}`}
          >
            { isOfferComplete(row.uid) ? 'Revisit' : 'Start'} 
          </button>
        </div>
      ),
    },
    {
      name: 'Offer Details',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { row.offeredRelayIds?.length > 0 && isOfferComplete(row.uid) &&
            <button onClick={() => handleViewOfferDetails(row.uid)} className={`inquiry-button`}>
              View ({row.offeredRelayIds?.length || 0})
            </button>
          }
        </div>
      ),
    },
    {
      name: 'Owner',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { !isCaseClaimed(row.uid) &&
            <button onClick={() => handleClaimCase(row.uid)} className={`inquiry-button`}>
              Claim
            </button>
          }
          { isCaseClaimed(row.uid) && getClaimedBy(row.uid) }
        </div>
      ),
    }
  ];

  return (
    <div className="offer-container">
      {isLoading ? (
        <h1>Loading...</h1>
      ) : (
        <div className="data-table">
          <div className="form-section">
            <h2>Offer Manager</h2>
            <div className="search-container">              
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstNameFilter">First Name</label>
                  <input type="text" name="firstName" value={searchFormValues.firstName} onChange={handleFilterChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="lastNameFilter">Last Name</label>
                  <input type="text" name="lastName" value={searchFormValues.lastName} onChange={handleFilterChange} />
                </div>
                <div className="left-align search-button">
                  <button className='shopped-button' onClick={handleSearch} disabled={searchFormValues.lastName.length === 0} >
                    Search
                  </button>
                </div>
                <div className="left-align search-button">
                  <button className='reset-button' onClick={handleClear}  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>

          <DataTable
            columns={columns}
            data={currentPageData}
            highlightOnHover
            pagination
            paginationPerPage={batchSize}
            paginationServer
            paginationComponent={() => (
              <div className="pagination">
                Rows per page:
                <select id="rowsPerPageSelect" className="pagination-select" value={batchSize} onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || isLoading}
                >
                  Previous
                </button>
                <button
                  className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!hasNextPage || isLoading}
                >
                  Next
                </button>
              </div>
            )}
          />
        </div>
      )}

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          {selectedExchangeRelay && (
            <OfferDetail
              exchangeRelay={selectedExchangeRelay}
              firstName={selectedCase?.firstName || ""}
              lastName={selectedCase?.lastName || ""}
              setModalOpen={setModalOpen}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CarrierCaseManager;
