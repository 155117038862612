import { useDropzone } from 'react-dropzone';
import "./hippaupload.css";
import FileList from './filelist';
import { FormEvent, SetStateAction, useState } from "react";
import { uploadExchangeFile } from '../../services/file-upload.service';
import toast from 'react-hot-toast';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../../firebaseConfig';
import Spinner from '../Utilitity/Spinner';

interface UploadData {
  appId: string;
  files: File[];
}

type FileEntry = {
  originalFileName: string;
  fileName: string;
};

interface HippaUploadProps {
  caseUid: string;
  firstName: string; 
  lastName: string;
  appId: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
}

const HippaUpload: React.FC<HippaUploadProps> = ({ caseUid, firstName, lastName, appId, setModalOpen }) => {  
  const [isLoading, setIsLoading] = useState(false);  

  // Initialize state with form data
  const [uploadData, setUploadData] = useState<UploadData>({
    appId: appId,
    files: [],
  });
  const isSubmitDisabled = uploadData.files.length === 0;

  // Set up the dropzone for file uploads
  const onDrop = (acceptedFiles: File[]) => {
    setUploadData({
      ...uploadData,
      files: [...uploadData.files, ...acceptedFiles],
    });
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = uploadData.files.filter((_, i) => i !== index);
    setUploadData({
      ...uploadData,
      files: updatedFiles,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // Handle file upload and submission
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const submissionData = await buildSubmissionData();
    if (!submissionData) return;  // Exit if an error occurs in file upload
    await publishHippaUploadMessage(submissionData);
    setIsLoading(false);
    setModalOpen(false);
  };

  const publishHippaUploadMessage = async (submissionData: any) => {
    try {
      const functions = getFunctions(app);
      const publishMessage = httpsCallable(functions, "publishHippaUploadMessage");
      await publishMessage(submissionData);
    } catch (err) {
      toast.error('An unexpected error occurred. Please check files and try again.', { duration: 5000, });
    }
  };

  const buildSubmissionData = async (): Promise<{ appid: string; files: FileEntry[] } | null> => {
    const submissionData = {
      appid: appId || "",
      files: []
    };

    const fileUploadPromises = uploadData.files.map((file) => uploadFile(file, submissionData));
    await Promise.all(fileUploadPromises);
    return submissionData;
  };

  const uploadFile = async (file: File, submissionData: any) => {
    try {
      //change file name to end in _auth
      const result = await uploadExchangeFile(file);
      submissionData.files.push({ originalFileName: 'hippa_auth.pdf', fileName: result.fileName });
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('File upload failed. Please try again.');
      setIsLoading(false);
      return null;
    }
  };

  return (
    <div className="hippa-upload-container">
      {isLoading && <Spinner message="Processing... Please do not close or refresh the page." />}
      <div className="form-section">
        <h3>
          Hippa Upload Request - { firstName } { lastName }
        </h3>

        <form onSubmit={handleSubmit} className="hippa-upload-form">
          <div {...getRootProps()} className={`hippa-dropzone ${isDragActive ? 'active' : ''}`} >
            <input {...getInputProps({ accept: '.pdf' })} />
            { isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p> }
          </div>

          <button type="submit" disabled={isSubmitDisabled}>Submit</button>
        </form>
        
        <FileList files={uploadData.files} onDelete={(index) => handleFileDelete(index)} />
      </div>
    </div>
  );
};

export default HippaUpload;

