import "./carriersummaryviewer.css";

import { useParams } from 'react-router-dom';
import DocumentViewer from "../../components/DocumentViewer/documentviewer";
import EditableNote from "./editableNote";
import OfferDetail from "../../components/OfferDetail/offerdetail";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebaseConfig";
import { ExchangeRelay } from "../../models/exchangeRelay";
import { useCase } from "../../services/caseService";


const CarrierSummaryViewer = (props: any) => {
  const { uid, relayId, appId } = useParams<{ uid: string, relayId: string, appId: string }>();
  const [selectedExchangeRelay, setSelectedExchangeRelay] = useState<ExchangeRelay>();

  const { data: caseData } = useCase(uid!);
 
  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'exchangeRelay', relayId!)
      const relaySnapshot = await getDoc(docRef);
      if (relaySnapshot.exists()) {
        const exchangeRelay = relaySnapshot.data() as ExchangeRelay;
        setSelectedExchangeRelay(exchangeRelay);
      }
    }

    fetchData();
  }, [relayId]);
  
  return (
    <div className="carrier-viewer-container">
      <div className="workshop">
        <div className="underwriting-notes-header">INTERNAL UNDERWRITING NOTES</div>
        <EditableNote relayId={relayId!}/>
        <OfferDetail
          exchangeRelay={selectedExchangeRelay!}
          firstName={caseData?.firstName ?? ""}
          lastName={caseData?.lastName ?? ""}
        />
      </div>
      <div className="summary">
        <DocumentViewer uid={appId} />
      </div>
    </div>
  )
}

export default CarrierSummaryViewer;