import { useEffect, useState } from 'react';
import { selectAuth } from '../redux/slices/authSlice';
import { fetchPermissions } from '../services/user-permissions.service';
import { getEmailDomain } from '../utils/email';
import { useAppSelector } from './redux-hooks';

export const useUnderwritingReviewPermission = (): boolean => {
  const { userData } = useAppSelector(selectAuth);
  const [hasPermission, setHasPermission] = useState<boolean>(false);

  useEffect(() => {
    const checkPermission = async () => {
      if (userData?.email) {
        const domain = getEmailDomain(userData.email) || '';
        const permissions = await fetchPermissions(domain, 'underwriterReviewer');
        setHasPermission(permissions.length > 0);
      }
    };

    checkPermission();
  }, [userData]);

  return hasPermission;
};
