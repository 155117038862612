import  { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Case, CaseStatus, FeedbackRequest } from '../../../models/Case/case';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { selectAuth } from '../../../redux/slices/authSlice';
import { useFilteredData } from '../../../services/case-data.service';

import "./casemanager.css";
import { doc, updateDoc } from 'firebase/firestore';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { REQUEST_STATUS } from '../../../types/feedbackTypes';
import { InquiryStatus, SummaryStatus } from '../../../types/caseTypes';
import { db } from '../../../firebaseConfig';
import { CaseManagerSearchRequest } from '../../../types/caseSearchRequest';
import CaseSearchForm from '../../../components/CaseSearchForm/casesearchform';
import CaseFeedbackModal from '../../../components/FeedbackRequest/feedbackrequestmodal';
import CasePlacementEntry from '../../../components/CasePlacedEntry/caseplacemententry';
import ReviewRequestForm from '../../../components/ReviewRequest/reviewrequest';
import { useUnderwritingReviewPermission } from '../../../hooks/useUnderwritingReviewPermission';
import HippaUpload from '../../../components/HippaUpload/hippaupload';

const modalBoxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '80vh',
  overflowY: 'auto',
};

const CaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);

  // State declarations
  const [modalOpen, setModalOpen] = useState(false);
  const [hippaModalOpen, setHippaModalOpen] = useState(false);
  const [placedModalOpen, setPlacedModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [currentPageData, setCurrentPageData] = useState<Case[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [cachedPages, setCachedPages] = useState<Case[][]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [batchSize, setBatchSize] = useState(10);
  
  const initialValues: CaseManagerSearchRequest = {
    caseStatus: "Active",
    firstName: "",
    lastName: "",
    showOrgCases: false,
    batchSize: 10,
    userEmail: userData?.email || ""
  };
  const [searchFormValues, setSearchFormValues] = useState(initialValues);
  const [filters, setFilters] = useState<CaseManagerSearchRequest>(initialValues);

  const areFiltersDifferent = (a: CaseManagerSearchRequest, b: CaseManagerSearchRequest): boolean => {
    return Object.keys(a).some((key) => a[key as keyof CaseManagerSearchRequest] !== b[key as keyof CaseManagerSearchRequest]);
  };

  const handleSearch = () => {
    if (areFiltersDifferent(searchFormValues, filters)) {
      setCachedPages([])
      setCurrentPage(1);
      setLastVisible(null);
      setFilters(searchFormValues);
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSearchFormValues({
      ...searchFormValues,
      [name]: value,
    });
  };

  const { data, isLoading, refetch } = useFilteredData(filters, currentPage, lastVisible, setLastVisible);

  useEffect(() => {
    refetch().then((result: any) => {
      if (result?.data?.caseData) {
        setCachedPages([result.data.caseData]); // Initialize cached pages
        setCurrentPageData(result.data.caseData); // Set data for the first page
        setHasNextPage(result.data.hasNextPage); // Set pagination state
      }
    });
  }, [filters, refetch]);

  useEffect(() => {
    if (data) {
      if (data.caseData[0]?.uid === currentPageData[0]?.uid && data.caseData.length === currentPageData.length) {
        setCurrentPageData(data.caseData);
      }
    }
  }, [data, currentPageData]);

  const hasUnderwritingReviewPermission = useUnderwritingReviewPermission();
  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Placed', label: 'Placed' },
    { value: 'No Acceptable Offer', label: 'No Acceptable Offer' },
    { value: 'Will Not Shop', label: 'Will Not Shop' },
  ];
  if (hasUnderwritingReviewPermission) {
    statusOptions.push({ value: 'Underwriting Review', label: 'Underwriting Review' });
  }

  const handlePageChange = async (newPage: number) => {
    //if next clicked
    if (newPage > currentPage) {
      //if page not already in cache
      if (newPage > cachedPages.length) {
        const result = await refetch();
        if (result?.data?.caseData) {
          setHasNextPage(result.data.hasNextPage)
          setCachedPages((prev) => [...prev, result.data.caseData]);
          setCurrentPageData(result.data.caseData);
        }
      } else {
        const updatedPageData = cachedPages[newPage - 1] || [];
        setHasNextPage(batchSize === updatedPageData.length)
        setCurrentPageData(updatedPageData);
      }
    } else {
      //if previous clicked
      setHasNextPage(true)
      const updatedPageData = cachedPages[newPage - 1] || [];
      setCurrentPageData(updatedPageData);
    }
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newBatchSize: number) => {
    setBatchSize(newBatchSize);
    // Update the local search form state
    setSearchFormValues((prev) => ({ ...prev, batchSize: newBatchSize }));
    
    // Reset pagination state
    setCurrentPage(1);
    setCachedPages([]);
    setLastVisible(null);
    
    // Create new filters with the new batchSize
    const newFilters = { ...filters, batchSize: newBatchSize };
    setFilters(newFilters);
  };

  const handleOrgCasesChange = (checked: boolean) => {
    setSearchFormValues({
      ...searchFormValues,
      showOrgCases: checked,
    });
  }

  const handleViewCase = (uid: string) => navigate(`/case/${uid}`);
  const handleSendCase = (uid: string, appId: string) => navigate(`/sendqueue/${uid}/${appId}`);
  const handleViewOffer = (uid: string) => navigate(`/case/${uid}/offerDetail`);

  const getExchangeName = (): string => {
    if (userData?.exchangePartner === 'LS') {
      return 'Lion Street Exchange';
    }
  
    return 'AIN Exchange';
  };

  const handleReqestFeedback = (caseUid: string) => {
    const caseData = data?.caseData.find((c) => c.uid === caseUid);

    if (caseData) {
      setSelectedCase(caseData);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => setModalOpen(false);
  const handleCloseHippaModal = () => setHippaModalOpen(false);
  const handleClosePlacedModal = () => setPlacedModalOpen(false);
  const handleReviewModalClose = () => setReviewModalOpen(false);

  const handleHippaNeeded = (caseUid: string) => {
    const caseData = data?.caseData.find((c) => c.uid === caseUid);
    if (caseData) {
      setSelectedCase(caseData);
      setHippaModalOpen(true);
    }
  };

  const handleStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, caseUid: string) => {
    const newStatus = e.target.value as CaseStatus;
    setSelectedStatus(newStatus)
    const caseData = currentPageData.find(c => c.uid === caseUid);
    if (!caseData) return;
  
    try {
      if (newStatus === 'Placed' || newStatus === 'No Acceptable Offer') {
        setSelectedCase(caseData);
        setPlacedModalOpen(true);
      } else if (newStatus === 'Underwriting Review') {
        setSelectedCase(caseData);
        setReviewModalOpen(true);
      } else {
        const docRef = doc(db, "cases", caseUid);
        await updateDoc(docRef, { caseStatus: newStatus, placedCarrier: '', placedNote: '' });
        updateCaseStatusInPages(caseUid, newStatus);
      }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const updateCaseStatusInPages = (caseUid: string, newStatus: CaseStatus) => {
    setCachedPages((prev) => 
      prev.map((page) =>
        page.map((caseItem) =>
          caseItem.uid === caseUid ? { ...caseItem, caseStatus: newStatus } : caseItem
        )
      )
    );

    setCurrentPageData((prev) => 
      prev.map((caseItem) =>
          caseItem.uid === caseUid ? { ...caseItem, caseStatus: newStatus } : caseItem
      )
    );
  };

    const getClaimedBy = (agentEmail: string, agentFirstName: string, agentLastName: string) => {
      if (agentLastName?.length > 0) {
        return `${agentFirstName} ${agentLastName}`.trim();
      }

      return agentEmail;
    };
  
    const isCaseClaimed = (agentEmail: string) => {
      return !agentEmail.startsWith("organization@");
    }
  
    const handleClaimCase = async (caseUid: string) => {
      const caseRef = doc(db, 'cases', caseUid);
      await updateDoc(caseRef, { agentEmail: userData?.email, agentfirstName: userData?.firstName, agentlastName: userData?.lastName});
    };
  
  const columns: any[] = [
    {  
      name: 'Status',
      cell: (row: { uid: string, caseStatus: string }) => (
        <select id="caseStatus" value={row.caseStatus} onChange={(e) => handleStatusChange(e, row.uid)} className="case-select" >
          {statusOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ),
    },
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {
      name: 'Summary Status',
      cell: (row: { uid: string; appId: string; summaryStatus: string  }) => {
        const content =
          row.summaryStatus === SummaryStatus.COMPLETED ? (
            <a href={`/case/${row.appId}/summary`}>
              {row.summaryStatus}
            </a>
          ) : row.summaryStatus === 'HIPPA Missing' ? (
            <button
              type="button"
              onClick={() => handleHippaNeeded(row.uid)}
              style={{
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                color: 'red',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              HIPAA Needed
            </button>
          ) : (
            row.summaryStatus
          );
          
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {content}
          </div>
        );
      },
    },
    {
      name: 'Informal Inquiry',
      cell: (row: { uid: string; inquiryStatus: string }) => (
        <div className="left-align">
          <button
            onClick={() => handleViewCase(row.uid)}
            className={`inquiry-button ${row.inquiryStatus !== InquiryStatus.COMPLETED ? 'incomplete-status' : ''}`}
          >
            {row.inquiryStatus !== InquiryStatus.COMPLETED ? 'Start' : 'Revisit'}
          </button>
        </div>
      ),
    },
    {
      name: getExchangeName(),
      cell: (row: { uid: string, appId: string, relayIds: string[] }) => (
        <div className="left-align">
          <button onClick={() => handleSendCase(row.uid, row.appId)} className="inquiry-button">
            Shop ({row.relayIds?.length || 0})
          </button>
        </div>
      ),
    },
    {
      name: 'Offers',
      cell: (row: { uid: string, offeredRelayIds: string[] }) => (
        <div className="left-align">
          { row.offeredRelayIds?.length > 0 &&
            <button onClick={() => handleViewOffer(row.uid)} className={`inquiry-button`}>
              View ({row.offeredRelayIds?.length || 0})
            </button>
          }
        </div>
      ),
    },
    {
      name: 'Feedback',
      cell: (row: { uid: string, feedbackRequest: FeedbackRequest }) => (
        <div className="left-align">
          <button onClick={() => handleReqestFeedback(row.uid)} 
            className={`feedback-button 
              ${row.feedbackRequest?.feedbackStatus === REQUEST_STATUS.RESPONDED ? 'feedback-responded' : ''}
              ${row.feedbackRequest?.feedbackStatus === REQUEST_STATUS.REQUESTED ? 'feedback-requested' : ''}
            `}  
          >
            {row.feedbackRequest === undefined ? 'Submit' : row.feedbackRequest.feedbackStatus }
          </button>
        </div>
      ),
    },
    // Conditionally add the "Owner" column
    ...(filters.showOrgCases
    ? [
        {
          name: 'Owner',
          cell: (row: { uid: string, agentEmail: string, agentfirstName: string, agentlastName: string }) => (
            <div className="left-align">
              {!isCaseClaimed(row.agentEmail) ? (
                <button onClick={() => handleClaimCase(row.uid)} className="inquiry-button">
                  Claim
                </button>
              ) : (
                getClaimedBy(row.agentEmail, row.agentfirstName, row.agentlastName)
              )}
            </div>
          ),
        },
      ]
    : []),
  ];

  return (
    <div className='casemanager-container'>      
      {isLoading && <h1>Loading...</h1>}
      {!isLoading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Trial Manager</h2>

            <CaseSearchForm
              values={searchFormValues}
              onChange={handleFilterChange}
              onSearch={handleSearch}
              onOrgCasesChange={handleOrgCasesChange}
            />
          </div>

          <DataTable
            columns={columns}
            data={currentPageData}
            highlightOnHover
            pagination
            paginationPerPage={batchSize}
            paginationServer
            paginationComponent={() => (
              <div className="pagination">
                Rows per page:
                <select id="rowsPerPageSelect" className="pagination-select" value={batchSize} onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
                <button className="pagination-button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1 || isLoading}
                >
                  Previous
                </button>
                <button className="pagination-button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!hasNextPage || isLoading}
                >
                  Next
                </button>
              </div>
            )}
          />
        </div>
      }
      
      <CaseFeedbackModal
        open={modalOpen}
        onClose={handleCloseModal}
        caseUid={selectedCase?.uid || ""}
        firstName={selectedCase?.firstName || ""}
        lastName={selectedCase?.lastName || ""}
        setModalOpen={setModalOpen}
      />

      <Modal open={reviewModalOpen} onClose={handleReviewModalClose}>
        <Box sx={modalBoxStyle}>
          <ReviewRequestForm
            caseUid={selectedCase?.uid || ""}
            firstName={selectedCase?.firstName || ""}
            lastName={selectedCase?.lastName || ""}
            setModalOpen={setReviewModalOpen}
            onCaseStatusUpdate={(status: CaseStatus) =>
              updateCaseStatusInPages(selectedCase?.uid || "", status)
            }
          />
        </Box>
      </Modal>

      <Modal open={placedModalOpen} onClose={handleClosePlacedModal}>
        <Box sx={modalBoxStyle}>
          <CasePlacementEntry
            placed={selectedStatus === 'Placed'}
            caseUid={selectedCase?.uid || ""}
            firstName={selectedCase?.firstName || ""}
            lastName={selectedCase?.lastName || ""}
            setModalOpen={setPlacedModalOpen}
            onCaseStatusUpdate={(status: CaseStatus) =>
              updateCaseStatusInPages(selectedCase?.uid || "", status)
            }
          />
        </Box>
      </Modal>

      <Modal open={hippaModalOpen} onClose={handleCloseHippaModal}>
        <Box sx={modalBoxStyle}>
          <HippaUpload
            caseUid={selectedCase?.uid || ""}
            firstName={selectedCase?.firstName || ""}
            lastName={selectedCase?.lastName || ""}
            appId={selectedCase?.appId || ""}
            setModalOpen={setHippaModalOpen}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default CaseManager;
