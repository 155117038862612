// List of specific exempt emails
const EXEMPT_EMAILS = [
  "blount_patrick@msn.com",
  "pblount00@gmail.com",
  "hcb@advisorsexcel.com",
  "hcb@lifebrokeragellc.com",
  "hcb@protective.com",
  "hcb@symetra.com",
  "hcbowm@gmail.com",
  "hcb@nationwide.com"
];

// List of exempt email domains
const EXEMPT_DOMAINS = ["@apeirongate.com"];

export const isExemptEmail = (email: string): boolean => {
  return (
    EXEMPT_EMAILS.includes(email) ||
    EXEMPT_DOMAINS.some((domain) => email.endsWith(domain))
  );
};
