import React, { FC, useEffect, useState } from 'react';
import { User } from '../../../models/User';
import { ALL_PERMISSIONS } from '../../../types/permissions';
import { doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';
import { getEmailDomain } from '../../../utils/email';
import { UserPermission } from '../../../models/userPermissions';

interface PermissionAssignerProps {
  user: User;
  onSave: (updatedPermissions: string[]) => void;
}

const PermissionAssigner: FC<PermissionAssignerProps> = ({ user, onSave }) => {
  const [selectedPermissions, setSelectedPermissions] = useState<Set<string>>(new Set());

  useEffect(() => {    
    if (user.roles) {
      setSelectedPermissions(new Set(user.roles));
    } else {
      setSelectedPermissions(new Set());
    }
  }, [user]);

  // Toggle permission on checkbox change
  const handleToggle = (permissionValue: string) => {
    setSelectedPermissions(prev => {
      const newPermissions = new Set(prev);
      if (newPermissions.has(permissionValue)) {
        newPermissions.delete(permissionValue);
      } else {
        newPermissions.add(permissionValue);
      }
      return newPermissions;
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedPermissions = Array.from(selectedPermissions);
    onSave(updatedPermissions);

    // Iterate over permissions that have an associated Firestore collection.
    const firestoreUpdates = ALL_PERMISSIONS
      .filter(permission => permission.firestoreCollection)
      .map(async (permission) => {
        const ref = doc(db, permission.firestoreCollection!, user.uid);
        if (selectedPermissions.has(permission.value)) {
          const userPermission: UserPermission = { 
            userUid: user.uid,
            permission: permission.value,
            email: user.email,
            emailDomain: getEmailDomain(user.email) ?? ''
          };
          return setDoc(ref, userPermission, { merge: true });
        } else {
          return deleteDoc(ref);
        }
      });

    try {
      await Promise.all(firestoreUpdates);
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };

  
  return (
    <form onSubmit={handleSubmit}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {ALL_PERMISSIONS.map(permission => (
          <li key={permission.value}>
            <label>
              <input
                type="checkbox"
                checked={selectedPermissions.has(permission.value)}
                onChange={() => handleToggle(permission.value)}
              />
              <strong></strong> {permission.description}
            </label>
          </li>
        ))}
      </ul>
      <button type="submit">Save Permissions</button>
    </form>
  );
};

export default PermissionAssigner;
