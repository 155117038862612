interface OfferDetailsTableProps {
  rows: {
    carrier?: string;
    offerDate?: string;
    tentativeOffer?: string;
    shoppedDate?: string;
    placed?: string;
  }[];
}

const OfferDetailsTable: React.FC<OfferDetailsTableProps> = ({ rows }) => {
  return (
    <table className="offer-table">
      <thead>
        <tr>
          <th>CARRIER</th>
          <th>OFFER DATE</th>
          <th>TENTATIVE OFFER</th>
          <th>SHOPPED</th>
          <th>PLACED</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            <td>{row.carrier || ""}</td>
            <td>{row.offerDate || ""}</td>
            <td>{row.tentativeOffer || ""}</td>
            <td>{row.shoppedDate || ""}</td>
            <td>{row.placed || ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OfferDetailsTable;
