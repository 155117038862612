import { SetStateAction, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

import "./reviewrequest.css";
import toast from 'react-hot-toast';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { getEmailDomain } from '../../utils/email';
import { Case, CaseStatus } from '../../models/Case/case';
import { fetchPermissions } from '../../services/user-permissions.service';
import { fetchUserByEmail } from '../../services/user.service';

interface ReviewRequestProps {
  caseUid: string;
  firstName: string; 
  lastName: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
  onCaseStatusUpdate: (status: CaseStatus) => void;
}

type Reviewer = {
  email: string;
  firstName: string;
  lastName: string;
}

const ReviewRequestForm: React.FC<ReviewRequestProps> = ({ caseUid, firstName, lastName, setModalOpen, onCaseStatusUpdate }) => {
  const { userData } = useAppSelector(selectAuth);  
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [reviewers, setReviewers] = useState<Reviewer[]>([])

  const { handleSubmit, setValue, register } = useForm<Case>();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch case data if caseUid is provided
        if (caseUid) {
          const caseDocRef = doc(db, "cases", caseUid);
          const caseDoc = await getDoc(caseDocRef);
          if (caseDoc.exists()) {
            const caseData = caseDoc.data();
            // Set each field from the case data into the form
            Object.entries(caseData).forEach(([key, value]) => {
              setValue(key as keyof Case, value);
            });
          } else {
            console.warn("No such document in cases!");
          }
        }
  
        // Fetch reviewers if userData exists
        if (userData) {
          const userEmailDomain = getEmailDomain(userData.email) || "";
          const permissions = await fetchPermissions(userEmailDomain, "underwriterReviewer");
  
          const reviewerPromises = permissions.map(async (permission) => {
            const fetchedUser = await fetchUserByEmail(permission.email);
            if (fetchedUser) {
              return {
                email: permission.email,
                firstName: fetchedUser.firstName,
                lastName: fetchedUser.lastName,
              } as Reviewer;
            }
            return null;
          });
  
          const reviewersResult = await Promise.all(reviewerPromises);
          setReviewers(reviewersResult.filter((r): r is Reviewer => r !== null));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [setValue, caseUid, userData]);
  

  const onSubmit = async (caseData: Case) => {
    try {
      if (!caseData) {
        console.error("FeedbackRequest is undefined.");
        return;
      }

      if (caseData.underwritingReviewer?.length === 0) {
        toast.error("Reviewer selection is required.");
        return;
      }

      caseData.caseStatus =  'Underwriting Review';
      const docRef = doc(db, "cases", caseUid);
      await updateDoc(docRef, caseData);
  
      toast.success("Feedback requested successfully!");
      setModalOpen(false);
      onCaseStatusUpdate(caseData.caseStatus);
    } catch (err: any) {
      // Log and display error
      console.error("Error saving data:", err);
      setError(err.message || "Error saving data");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className='case-container'>
      {!loading &&
        <>
          <div className="review-request-container">
            <div className="form-section">
              <h3>
              Underwrting Review - { firstName } { lastName }
              </h3>
            </div>
          
            <form onSubmit={handleSubmit(onSubmit)} className="review-form">
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="advisor">Send To:</label>
                  <select id="underwritingReviewer" {...register("underwritingReviewer")} style={{ width: 'auto', display: 'inline-block' }}>
                    <option value=""></option>
                    {reviewers.map((contact) => (
                      <option key={contact.email} value={contact.email}>
                        {contact.firstName} {contact.lastName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <button type="submit">Submit</button>
            </form>
          </div>
        </>
      }
    </div>
  );
};

export default ReviewRequestForm;

