interface ApplicantHeaderTableProps {
  applicantLast: string;
  applicantFirst: string;
  marketingRep?: string;
  caseManager?: string;
  agent?: string;
  targetPremium?: string;
  faceAmount?: string;
}

const ApplicantHeaderTable: React.FC<ApplicantHeaderTableProps> = ({
  applicantLast,
  applicantFirst,
  marketingRep,
  caseManager,
  agent,
  targetPremium,
  faceAmount,
}) => {
  return (
    <table className="header-table">
      <tbody>
        <tr>
          <th>
            <div className="header-table-last-name">{applicantLast},</div>
            <div className="header-table-first-name">{applicantFirst}</div>
          </th>
          {marketingRep && marketingRep.length > 0 && (
            <td>
              <div className="header-table-td">
                <span className="pipe-text">M</span>
                <span>{marketingRep}</span>
              </div>
            </td>
          )}
          {caseManager && caseManager.length > 0 && (
            <td>
              <div className="header-table-td">
                <span className="pipe-text">C</span>
                <span>{caseManager}</span>
              </div>
            </td>
          )}
          {agent && agent.length > 0 && (
            <td>
              <div className="header-table-td">
                <span className="pipe-text">A</span>
                <span>{agent}</span>
              </div>
            </td>
          )}
          {targetPremium && targetPremium.length > 0 && (
            <td>
              <div className="header-table-td">
                <span className="pipe-text">TP</span>
                <span>{targetPremium}</span>
              </div>
            </td>
          )}
          {faceAmount && faceAmount.length > 0 && (
            <td>
              <div className="header-table-td">
                <span className="pipe-text">FA</span>
                <span>{faceAmount}</span>
              </div>
            </td>
          )}
          {/* Empty cell extends banner if inquiry not filled out */}
          <td>
            <div className="header-table-td"></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ApplicantHeaderTable;
