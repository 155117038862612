import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { User } from "../models/User";
import { auth, db } from "../firebaseConfig";

export const registerUser = async (
    registerInput: { firstname: string; lastname: string; email: string; password: string; agency: string; }, 
    exchangePartner: string,
    carrierRole: string,
    isCarrier: boolean
  ) => {
  const userCredential = await createUserWithEmailAndPassword(auth, registerInput.email, registerInput.password);
  const user = userCredential.user;

  // Update the user's profile with the display name
  //await updateProfile(user, { displayName: `${values.firstname} ${values.lastname}` });

  // Create a document in Firestore with additional user information
  const userData: User = {
    uid: userCredential.user.uid,
    firstName: registerInput.firstname,
    lastName: registerInput.lastname,
    email: registerInput.email.toLocaleLowerCase(),
    carrierRole: carrierRole.length ? carrierRole : '',
    agency: registerInput.agency,
    exchangePartner: exchangePartner,
    createdAt: new Date(),
    isCarrier: isCarrier
  };

  await setDoc(doc(db, "users", user.uid), userData);
};
