import { Dialog, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import TermsAndConditions from './TermsAndConditions';

interface TermsModalProps {
  open: boolean;
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <Typography variant="body2">
        <TermsAndConditions />

      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default TermsModal;
