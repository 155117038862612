import React from 'react';
import './casesearchform.css';

interface CaseSearchFormProps {
  values: {
    caseStatus: string;
    firstName: string;
    lastName: string;
    showOrgCases: boolean;
  };
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onSearch: () => void;
  onOrgCasesChange?: (checked: boolean) => void;
}

const CaseSearchForm: React.FC<CaseSearchFormProps> = ({
  values,
  onChange,
  onSearch,
  onOrgCasesChange,
}) => {
  return (
    <div className="search-container">              
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="statusFilter">Status</label>
          <select id="statusFilter" name="caseStatus" value={values.caseStatus} onChange={onChange} >
            <option value="Active">Active</option>
            <option value="Hold">Hold</option>
            <option value="Placed">Placed</option>
            <option value="No Acceptable Offer">No Acceptable Offer</option>
            <option value="Underwriting Review">Underwriting Review</option>
            <option value="Will Not Shop">Will Not Shop</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="firstNameFilter">First Name</label>
          <input type="text" name="firstName" value={values.firstName} onChange={onChange} />
        </div>
        <div className="form-group">
          <label htmlFor="lastNameFilter">Last Name</label>
          <input type="text" name="lastName" value={values.lastName} onChange={onChange} />
        </div>
        <div className="left-align search-button">
          <button className='shopped-button' onClick={onSearch} >
          Search
          </button>
        </div>
      </div>
      { onOrgCasesChange && 
        <div className="form-row">
          <label>
            <input type="checkbox" checked={values.showOrgCases} onChange={(e) => onOrgCasesChange(e.target.checked)} />
            &nbsp; Show all cases for organization
          </label>
        </div>
      }
    </div>
  );
};

export default CaseSearchForm;
