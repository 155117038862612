import { SetStateAction, useEffect, useState } from 'react';
import { useForm, } from 'react-hook-form';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

import "./offerdetail.css";
import { ExchangeRelay } from '../../models/exchangeRelay';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { useDropzone } from 'react-dropzone';
import FileList from '../HippaUpload/filelist';

interface OfferDetailProps {
  exchangeRelay: ExchangeRelay; 
  firstName: string; 
  lastName: string;
  setModalOpen?: (event: SetStateAction<boolean>) => void;
}

const OfferDetail: React.FC<OfferDetailProps> = ({ exchangeRelay, firstName, lastName, setModalOpen }) => {
  const { userData } = useAppSelector(selectAuth);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [offerFiles, setOfferFiles] = useState<File[]>([]);
  const isSubmitDisabled = offerFiles.length === 0 && !userData?.isCarrier;

  const { register, handleSubmit, setValue } = useForm<ExchangeRelay>();

  useEffect(() => {
    const fetchExchangeRelay = async () => {
      try {
        setLoading(true);
        setError(null);

        // Populate form fields with case data
        Object.keys(exchangeRelay).forEach(key => {
          setValue(key as keyof ExchangeRelay, exchangeRelay[key as keyof ExchangeRelay]);
        });
        setValue("offerBy", userData?.email);     
      } catch (err: any) {
        setError(err.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRelay();
  }, [setValue, exchangeRelay, userData]);

  const onSubmit = async (data: ExchangeRelay) => {
    if (!data.id) {
      setError("Invalid data");
      return;
    }

    const fileUploadPromises = offerFiles.map((file) => uploadFile(file, data));
    await Promise.all(fileUploadPromises);

    try {
      const docRef = doc(db, "exchangeRelay", data.id);
      await updateDoc(docRef, data);
      toast.success('Offer saved successfully!');
      if (setModalOpen) {
        setModalOpen(false);
      }
    } catch (err: any) {
      setError(err.message || "Error saving data");
    }
  };

  const uploadFile = async (file: File, exchangeRelay: ExchangeRelay) => {
    const bucketName = 'offer-documentation';
    const data = new FormData();
    data.append('caseUid', exchangeRelay.caseUid ?? '');
    data.append('file', file);

    try {
      const response = await fetch(`https://us-central1-apeirongateportal.cloudfunctions.net/CS-uploadToFirebaseStorage/upload?bucketName=${bucketName}`, 
      {
        method: 'POST',
        body: data,
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('File upload failed. Please try again.');
      return null;
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    setOfferFiles([...offerFiles, ...acceptedFiles]);
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = offerFiles.filter((_, i) => i !== index);
    setOfferFiles(updatedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  
  return (
    <div className='case-container'>
      {!loading &&
        <>
          <div className="form-section">
            <h3>
            { exchangeRelay?.carrierCode?.toLocaleUpperCase() } OFFER - { firstName } { lastName }
            </h3>
          </div>
          
          <form onSubmit={handleSubmit(onSubmit)} className="exchange-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="carrierId">ID #</label>
                <input id="carrierId" {...register('carrierId')} />
              </div>

              <div className="form-group">
                <label htmlFor="offerAmount">Offer Amount</label>
                <input id="offerAmount" {...register('offerAmount')} />
              </div>

              <div className="form-group">
                <label htmlFor="offerDate">Offer Date</label>
                <input id="offerDate" type="date" {...register('offerDate')} />
              </div>
            </div>

            <div className="form-row"><label htmlFor="tentativeOffer">Tentative Offer</label></div>
            <div className="form-row">
              <div className="form-group">
                <select id="tentativeOffer" {...register('tentativeOffer')}>
                <option value=""></option>
                <option value="Preferred Best">Preferred Best</option>
                <option value="Preferred">Preferred</option>
                <option value="Standard Plus">Standard Plus</option>
                <option value="Standard">Standard</option>
                <option value="Table 1/A">Table 1/A</option>
                <option value="Table 2/B">Table 2/B</option>
                <option value="Table 3/C">Table 3/C</option>
                <option value="Table 4/D">Table 4/D</option>
                <option value="Table 5/E">Table 5/E</option>
                <option value="Table 6/F">Table 6/F</option>
                <option value="Highly Rated">Highly Rated</option>
                <option value="Postpone">Postpone</option>
                <option value="Decline">Decline</option>
                </select>
              </div>

              <div className="form-group" >
                <select id="tentativeOfferSubCategory" {...register('tentativeOfferSubCategory')}>
                  <option value=""></option>
                  <option value="Tobacco">Tobacco</option>
                  <option value="Non Tobacco">Non Tobacco</option>
                  <option value="Smoker">Smoker</option>
                  <option value="Non Smoker">Non Smoker</option>
                </select>
              </div>
            </div>

            <div className="form-row"><label htmlFor="riderOffer">Rider Offer</label></div>
            <div className="form-row">
              <div className="form-group">
                <select id="riderOffer" {...register('riderOffer')}>
                <option value=""></option>
                <option value="Preferred Best">Preferred Best</option>
                <option value="Preferred">Preferred</option>
                <option value="Standard Plus">Standard Plus</option>
                <option value="Standard">Standard</option>
                <option value="Table 1/A">Table 1/A</option>
                <option value="Table 2/B">Table 2/B</option>
                <option value="Table 3/C">Table 3/C</option>
                <option value="Table 4/D">Table 4/D</option>
                <option value="Table 5/E">Table 5/E</option>
                <option value="Table 6/F">Table 6/F</option>
                <option value="Highly Rated">Highly Rated</option>
                <option value="Postpone">Postpone</option>
                <option value="Decline">Decline</option>
                </select>
              </div>

              <div className="form-group" >
                <select id="riderOfferSubCategory" {...register('riderOfferSubCategory')}>
                  <option value=""></option>
                  <option value="Tobacco">Tobacco</option>
                  <option value="Non Tobacco">Non Tobacco</option>
                  <option value="Smoker">Smoker</option>
                  <option value="Non Smoker">Non Smoker</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group-wide">
                <label htmlFor="offerExplanation">Offer Explanation (Displayed to all Carriers)</label>
                <textarea id="offerExplanation" {...register('offerExplanation')} style={{height: '100px'}} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group-wide">
                <label htmlFor="offerDetails">Offer Details and Subject to Information</label>
                <textarea id="offerDetails" {...register('offerDetails')} style={{height: '100px'}} />
              </div>
            </div>

            { !userData?.isCarrier && 
              <>
                <div className="form-group-wide">
                  <label htmlFor="offerDetails">Offer Documentation (Required)</label>
                  <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`} >
                    <input {...getInputProps({ accept: '.pdf, .tiff, .tif, .jpg, .png' })} />
                      { isDragActive ? <p>Drop the files here...</p> : <p>Drag & drop files here, or click to select files</p> }
                  </div>
                </div>
                
                <FileList files={offerFiles} onDelete={(index) => handleFileDelete(index)} />
              </>
            }

            <button type="submit" disabled={isSubmitDisabled}>Submit</button>
          </form>
        </>
      }
    </div>
  );
};

export default OfferDetail;

