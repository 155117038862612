import { db } from '../firebaseConfig';
import {
  collection,
  query,
  where,
  limit,
  getDocs
} from 'firebase/firestore';
import { getEmailDomain } from '../utils/email';
import { useQuery, UseQueryOptions } from 'react-query';

const agencyMappingRef = collection(db, 'agencyMappings');

const getAgency = async (email: string): Promise<string | undefined> => {
  const emailDomain = getEmailDomain(email);

  const agencyQuery = query(
    agencyMappingRef,
    where('agentDomain', '==', emailDomain),
    limit(1)
  );

  const snapshot = await getDocs(agencyQuery);
  return snapshot.docs.length > 0 ? snapshot.docs[0].data().agency : undefined;
};


export const useAgency = (
  email: string | undefined,
  options?: UseQueryOptions<string | undefined, Error>
) => {
  return useQuery<string | undefined, Error>(
    ['agencyMapping', email],
    () => getAgency(email!),
    {
      enabled: !!email,
      staleTime: 24 * 60 * 60 * 1000,
      cacheTime: 24 * 60 * 60 * 1000,
      ...options,
    }
  );
};
