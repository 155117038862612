export const TermsAndConditions = () => {
  return (
    <div>
      <p></p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>ACCEPTANCE OF TERMS OF SERVICE</strong>
          </span>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>THESE TERMS &amp; CONDITIONS OF SERVICE </strong>
          </span>
        </span>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            (the &ldquo;Agreement&rdquo;) constitutes a binding contract between you (the &ldquo;User,&rdquo; &ldquo;you,&rdquo; or &ldquo;your&rdquo;) and Apeiron Gate, LLC
          </span>
        </span>{" "}
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            (&ldquo;Apeiron Gate,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;). The Agreement, together with any documents expressly incorporated by reference, govern your access to and use of our services (the &ldquo;Service(s)&rdquo;) whether as a guest or a registered user of The Exchange at OpenTheGateX.com (the &ldquo;Website&rdquo;).
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center" }}>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>
              Please read carefully all of the terms and conditions of this user
              agreement, the terms of the above policies, and each of the other
              terms and agreements that apply to you.
            </strong>
          </span>
        </span>
      </p>
      <p style={{ textAlign: "center" }}>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Last Modified - March 2025</strong>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>TERMS OF SERVICE</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Purpose</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            Apeiron Gate has developed, or is providing on behalf of a third party,
            a product, service, functionality, and/or feature (the &ldquo;Service&rdquo;)
            as outlined in an associated Order. This Service offers a streamlined
            solution for submitting informal applications and tracking their progress
            with participating Life Insurance Carrier partners.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Changes to the Terms of Service</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            We may revise and update this Agreement at our sole discretion. Changes
            become effective immediately upon posting. Your continued use of the
            Website constitutes acceptance of any modifications. You are responsible
            for reviewing this Agreement periodically to stay informed of any updates.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Permitted Applications</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            Subject to the terms of this Agreement, Apeiron Gate grants you a
            non-exclusive, non-transferable, limited license to use the Service
            solely for the stated Purpose. You agree to use the Service only within
            your organization and with Life Insurance Carriers listed on the Service.
            This Agreement does not grant any ownership rights beyond the scope of
            the permitted use.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>User Representations and Warranties</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            By accessing this Website, you represent, warrant, and agree that:
          </span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                All information you provide is accurate, complete, and current.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                If you provide another person&rsquo;s personal information, you:
              </span>
            </span>
          </p>
          <ul>
            <li>
              <p>
                <span style={{ fontFamily: "Roboto, serif" }}>
                  <span style={{ fontSize: "small" }}>
                    Must do so in compliance with applicable laws.
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontFamily: "Roboto, serif" }}>
                  <span style={{ fontSize: "small" }}>
                    Must have authorization to share it.
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p>
                <span style={{ fontFamily: "Roboto, serif" }}>
                  <span style={{ fontSize: "small" }}>
                    Authorize us to process it as outlined in our Privacy Policy.
                  </span>
                </span>
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                You will not attempt to hack, bypass security measures, or compromise the
                integrity of the Website.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                You will not scrape, reverse engineer, or interfere with the Website.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                You will not use bots, crawlers, or automated tools to access or interact with
                the Website.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                You will not misuse trademarks, branding, or domain names in a manner that could
                be confused with Apeiron Gate.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Website and Account Security</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            We reserve the right to modify or withdraw this Website and any related services or
            materials at our discretion. We are not liable for temporary or extended unavailability
            of the Website. Access may be restricted at times, including for registered users.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            To access certain Website features, you may be required to provide registration details.
            You agree that all provided information is accurate, current, and complete. You must
            update this information as necessary.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            If you choose or are assigned a username, password, or other credentials, you must
            treat them as confidential. Do not share your credentials or allow unauthorized access
            to your account. Notify us immediately of any unauthorized access. We may disable
            credentials at our discretion if we believe a security breach has occurred.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Data Sharing</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            You confirm that you hold all applicable permissions or ownership rights to any data you
            submit through the Service, including PHI (as defined under HIPAA). You grant Apeiron Gate a
            worldwide, non-exclusive license to access, use, copy, distribute, and process such data for:
          </span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>Service maintenance and updates.</span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>Addressing technical issues.</span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Compliance with legal requirements.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Aggregating data for analytics and insights.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            We may disclose de-identified data in aggregate form for commercial purposes. Apeiron Gate retains
            all rights to any resulting data, analytics, and insights derived from the Service.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Prohibited Uses</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            You may use the Website only for lawful purposes and in compliance with this Agreement. You agree
            not to:
          </span>
        </span>
      </p>
      <ul>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Violate any applicable federal, state, local or international law or regulation (including, without
                limitation, any laws regarding the export of data or software to and from the US or other countries).
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>Exploit or harm minors.</span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Upload or transmit unlawful, defamatory, or malicious content.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                To send, knowingly receive, upload, download, use or re-use any material which does not comply with
                these Terms of Service.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Engage in unauthorized advertising or solicitations.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>Impersonate another person or entity.</span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Interfere with the operation or security of the Website.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Introduce malware, viruses, or harmful software.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Attempt unauthorized access to Website systems or data.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Use any manual process to monitor or copy any of the material on the Website, or for any other purpose
                not expressly authorized in this Agreement without our prior written consent.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Conduct any activity that could harm users, the Company, or its reputation.
              </span>
            </span>
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontFamily: "Roboto, serif" }}>
              <span style={{ fontSize: "small" }}>
                Otherwise attempt to interfere with the proper working of the Website.
              </span>
            </span>
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Third-Party Insurance Products</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            All insurance products quoted or offered through the Website are third-party offerings. We make no
            representations or warranties regarding these products and disclaim all liability related to their
            use. Questions or concerns about these products should be directed to the respective insurance carriers.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Disclaimer</strong>
          </span>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "#000000", fontFamily: "Roboto, serif", fontSize: "small" }}>
          THE SERVICE IS PROVIDED &ldquo;AS-IS&rdquo; WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES, THIS DISCLAIMER MAY NOT
          APPLY.{" "}
        </span>
        <span style={{ fontFamily: "Roboto, serif", fontSize: "small" }}>
          AG DOES NOT ASSUME, AND EXPRESSLY DISCLAIMS, ANY LIABILITY TO ANY PERSON OR ENTITY FOR LOSS OR DAMAGE
          CAUSED BY ERRORS OR OMISSIONS IN THE SERVICE, WHETHER SUCH ERRORS OR OMISSIONS RESULT FROM NEGLIGENCE,
          ACCIDENT, OR OTHER CAUSE. LSIS SHALL NOT SEEK DAMAGES FROM AG{" "}
        </span>
        <span style={{ color: "#000000", fontFamily: "Roboto, serif", fontSize: "small" }}>
          UENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES,{" "}
        </span>
        <span style={{ fontFamily: "Roboto, serif", fontSize: "small" }}>
          EVEN IF AG IS AWARE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGES.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Indemnification</strong>
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            You agree to indemnify and hold harmless Apeiron Gate, its affiliates, officers, directors, and
            employees from all claims, losses, and expenses arising from your use of the Service or your
            breach of this Agreement.
          </span>
        </span>
      </p>
      <p>
        <span style={{ fontFamily: "Roboto, serif" }}>
          <span style={{ fontSize: "small" }}>
            <strong>Miscellaneous</strong>
          </span>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ fontFamily: "Roboto, serif", fontSize: "small" }}>
          The User shall not assign, sublicense, or transfer this Agreement or any rights or obligations under
          this Agreement without the prior written consent of AG. An
        </span>
        <span style={{ color: "#000000", fontFamily: "Roboto, serif", fontSize: "small" }}>
          y attempt to assign, sublicense, or transfer is void. If any of the provisions of this Agreement
          become invalid, illegal, or unenforceable in any respect under any law, the validity, legality, and
          enforceability of the remaining provisions are not in any way to be affected or impaired. This
          Agreement, together with any attached exhibits or other appendices and Supplements, constitutes the
          entire agreement between the parties as to the subject matter contained herein. No modification,
          amendment, supplement to, or waiver of this Agreement is effective unless in writing and duly signed
          by an authorized representative of both parties. Any provision of this Agreement which contemplates
          performance subsequent to the termination of this Agreement, or which expressly states that it
          survives termination of this Agreement, survives such termination and continues in full effect until
          satisfied. This Agreement is governed by and shall be construed in accordance with the laws of the
          State of Delaware. Any litigation arising out of this Agreement shall be brought by either party in a
          court of competent jurisdiction located in Delaware. The prevailing party shall be awarded its
          reasonable attorneys' fees and costs in any lawsuit arising out of or related to this Agreement.
        </span>
      </p>
    </div>
  );
};

export default TermsAndConditions;
