import  { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Case, FeedbackRequest } from '../../models/Case/case';
import { useFilteredData } from '../../services/case-data.service';

import "./bgacasemanager.css";
import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';
import { REQUEST_STATUS } from '../../types/feedbackTypes';
import { Box, Modal } from '@mui/material';
import FeedbackRequestForm from '../../components/FeedbackRequest/feedbackrequest';
import { InquiryStatus, SummaryStatus } from '../../types/caseTypes';
import { CaseManagerSearchRequest } from '../../types/caseSearchRequest';
import CaseSearchForm from '../../components/CaseSearchForm/casesearchform';
import { SubmittingOrgCell } from '../../components/SubmittingOrgCell/submittingorgcell';

const BgaCaseManager = () => {
  const navigate = useNavigate();
  const { userData } = useAppSelector(selectAuth);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  
  const initialValues: CaseManagerSearchRequest = {
    caseStatus: "Active",
    firstName: "",
    lastName: "",
    showOrgCases: true,
    batchSize: 10,
    userEmail: userData?.email || ""
  };

  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFormValues, setSearchFormValues] = useState(initialValues);
  const [filters, setFilters] = useState<CaseManagerSearchRequest>(initialValues);
  const [cachedPages, setCachedPages] = useState<Case[][]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);

  const { data, isLoading, refetch } = useFilteredData(filters, currentPage, lastVisible, setLastVisible);

  const areFiltersDifferent = (a: CaseManagerSearchRequest, b: CaseManagerSearchRequest): boolean => {
    return Object.keys(a).some((key) => a[key as keyof CaseManagerSearchRequest] !== b[key as keyof CaseManagerSearchRequest]);
  };

  const handleSearch = () => {
    if (areFiltersDifferent(searchFormValues, filters)) {
      setCachedPages([])
      setCurrentPage(1);
      setLastVisible(null);
      setFilters(searchFormValues);
    }
  };

  const handlePageChange = async (newPage: number) => {
    if (newPage > currentPage) {
      if (newPage > cachedPages.length) {
        const result = await refetch();
        if (result?.data?.caseData) {
          setCachedPages((prev) => [...prev, result.data.caseData]);
          setHasNextPage(result.data.hasNextPage)
        }
      }
    } else {
      setHasNextPage(true)
    }
    setCurrentPage(newPage);
  };

  const currentPageData = cachedPages[currentPage - 1] || [];

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setSearchFormValues({
      ...searchFormValues,
      [name]: value,
    });
  };

  useEffect(() => {
    refetch().then((result) => {
      if (result?.data) {
        setCachedPages((prev) => [...prev, result.data.caseData]);
        setHasNextPage(result.data.hasNextPage)
      }
    });
  }, [filters, refetch]);
    
  const handleViewCase = (uid: string) => navigate(`/case/${uid}`);

  const handleReqestFeedback = (caseUid: string) => {
    const caseData = data?.caseData.find((c) => c.uid === caseUid);

    if (caseData) {
      setSelectedCase(caseData);
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const columns: any[] = [
    {  
      name: 'Submitting Org',
      cell: (row: { agentEmail: any; agency: any; }) => <SubmittingOrgCell agentEmail={row.agentEmail} agency={row.agency} />,
    },
    {  
      name: 'First Name',  
      selector: (row: { firstName: any; }) => row.firstName,
      sortable: true,
    },
    {  
      name: 'Last Name',  
      selector: (row: { lastName: any; }) => row.lastName,
      sortable: true,
    },
    {  
      name: 'DOB',  
      selector: (row: { dob: any; }) => {
        if (row.dob) {
          const [year, month, day] = row.dob.split('-');
          return `${month}/${day}/${year}`;
        }
        return '';
      },
    },
    {  
      name: 'Summary Status',
      cell: (row: { summaryStatus: string; appId: string }) => (
        row.summaryStatus === SummaryStatus.COMPLETED ? (
          <a href={`/case/${row.appId}/summary`}>
            {row.summaryStatus}
          </a>
        ) : (
          row.summaryStatus
        )
      ),
    },
    {
      name: 'Informal Inquiry',
      cell: (row: { uid: string; inquiryStatus: string }) => (
        <div className="left-align">
        <button
          onClick={() => handleViewCase(row.uid)}
          className={`inquiry-button ${row.inquiryStatus !== InquiryStatus.COMPLETED ? 'incomplete-status' : ''}`}
        >
          {row.inquiryStatus !== InquiryStatus.COMPLETED ? 'Start' : 'Revisit'}
        </button>
      </div>
      ),
    },
    {
      name: 'Feedback',
      cell: (row: { uid: string, feedbackRequest: FeedbackRequest }) => (
        <div className="left-align">
          <button onClick={() => handleReqestFeedback(row.uid)} 
            className={`feedback-button 
              ${row.feedbackRequest?.feedbackStatus === REQUEST_STATUS.REQUESTED ? 'feedback-responded' : ''}
            `}  
          >
            {row.feedbackRequest === undefined ? 'Submit' : row.feedbackRequest.feedbackStatus }
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className='casemanager-container'>      
      {isLoading && <h1>Loading...</h1>}
      {!isLoading && 
        <div className='data-table'>
          <div className="form-section">
            <h2>Trial Manager</h2>
            <CaseSearchForm
              values={searchFormValues}
              onChange={handleFilterChange}
              onSearch={handleSearch}
            />
          </div>
          <DataTable
            columns={columns}
            data={currentPageData}
            highlightOnHover
            pagination
            paginationPerPage={searchFormValues.batchSize}
            paginationServer
            paginationComponent={() => (
              <div className="pagination">
                <button className="pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || isLoading}>
                  Previous
                </button>
                <button className="pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={!hasNextPage || isLoading}>
                  Next
                </button>
              </div>
            )}
          />
        </div>
      }

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>          
          <FeedbackRequestForm 
            caseUid={selectedCase?.uid || ""}
            firstName={selectedCase?.firstName || ""}
            lastName={selectedCase?.lastName  || ""} 
            setModalOpen={setModalOpen}
          />
        </Box>
      </Modal>
      
    </div>
  );
};

export default BgaCaseManager;
