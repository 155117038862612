import { NavLink } from 'react-router-dom';

import "./subHeader.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { useAppSelector } from '../../hooks/redux-hooks';
import { selectAuth } from '../../redux/slices/authSlice';

const SubHeader = () => {  
  const { userData } = useAppSelector(selectAuth);

  return (
    <> { userData && 
      <nav className="subnavbar navbar-expand">
        <div className="navbar-nav justify-content-center">
          <li className="subnav-item">
            <NavLink to="/" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Home
            </NavLink>
          </li>
          { userData.isBga && 
            <li className="subnav-item">
              <NavLink to="/bgacasemanager" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
              Trial Manager
              </NavLink>
            </li>
          }
          { userData.isCarrier && 
            <li className="subnav-item">
              <NavLink to="/offermanager" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                Offer Manager
              </NavLink>
            </li>
          }
          { !userData.isCarrier && !userData.isBga &&
            <>
              <li className="subnav-item">
                <NavLink to="/casemanager" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                Trial Manager
                </NavLink>
              </li>
              
              <li className="subnav-item">
                <NavLink to="/caseupload" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                  Upload Records
                </NavLink>
              </li>
              <li className="subnav-item">
                <NavLink to="/orderrecords" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                  Order Records
                </NavLink>
              </li>
            </>
          }
          { (userData?.roles?.includes('admin') || userData?.isCarrier || userData?.roles?.includes('offerquality')) &&
            <li className="subnav-item">
              <NavLink to="/offerquality" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                Offer Quality Report
              </NavLink>
            </li>
          }
          { (userData?.roles?.includes('admin') || userData?.roles?.includes('opportunityreport')) &&
            <li className="subnav-item">
              <NavLink to="/opportunityreport" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                Opportunity Report
              </NavLink>
            </li>
          }
          { (userData?.roles?.includes('admin')) &&
            <li className="subnav-item">
              <NavLink to="/casedashboard" className={({ isActive }) => isActive ? 'sub-nav-link sub-nav-link-active' : 'sub-nav-link' } end>
                Dashboard
              </NavLink>
            </li>
          }
          { userData?.roles?.includes('admin') &&
            <li className="subnav-item dropdown">
              <span className="dropdown-toggle sub-nav-link" id="adminDropdown" data-bs-toggle="dropdown">
                Admin
              </span>
              <ul className="dropdown-menu" aria-labelledby="adminDropdown">
                <li>
                  <NavLink to="/admincasemanager" className="dropdown-item">
                    Admin Trial Manager
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/invoice" className="dropdown-item">
                    Invoice
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/invoicebackup" className="dropdown-item">
                    Invoice Backup
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/userpermissions" className="dropdown-item">
                    User Permissions
                  </NavLink>
                </li>
              </ul>
            </li>
          }
        </div>
      </nav>
      }
    </>
  );
};

export default SubHeader;

