import { useQuery } from 'react-query';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  getFirestore,
  Timestamp,
} from 'firebase/firestore';
import { Case } from '../models/Case/case';
import { ExchangeRelay } from '../models/exchangeRelay';

export interface ExchangeRelayAndCasesResult {
  relays: ExchangeRelay[];
  cases: Case[];
  lastVisible: QueryDocumentSnapshot | null;
  hasNextPage: boolean;
}

/**
 * Firestore query function that:
 * 1. Queries the exchangeRelay collection using the given carrierCode, a date filter, and pagination.
 * 2. Extracts the caseUids from the returned relay documents.
 * 3. Queries the cases collection to fetch matching cases.
 */
export const fetchExchangeRelayAndCases = async ({
  queryKey,
}: {
  queryKey: any;
}): Promise<ExchangeRelayAndCasesResult> => {
  // Destructure parameters from the query key
  const [
    _key,
    { carrierCode, batchSize, lastRelayDoc },
  ] = queryKey;
  console.log(_key);
  const db = getFirestore();
  const ninetyDaysAgo = Timestamp.fromDate(new Date(new Date().getTime() - 360 * 24 * 60 * 60 * 1000));

  // Build the exchangeRelay query.
  const exchangeRelayRef = collection(db, 'exchangeRelay');

  // Create the base query with filters and ordering.
  let relaysQuery = query(
    exchangeRelayRef,
    where('carrierCode', '==', carrierCode),
    where('createdDate', '>=', ninetyDaysAgo),
    orderBy('createdDate', 'desc'),
    limit(batchSize)
  );

  // For pagination: if lastRelayDoc is provided, start after it.
  if (lastRelayDoc) {
    relaysQuery = query(
      exchangeRelayRef,
      where('carrierCode', '==', carrierCode),
      where('createdDate', '>=', ninetyDaysAgo),
      orderBy('createdDate', 'desc'),
      startAfter(lastRelayDoc),
      limit(batchSize)
    );
  }

  // Execute the query for exchangeRelay documents.
  const relaySnapshot = await getDocs(relaysQuery);
  const relays: ExchangeRelay[] = relaySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...(doc.data() as Omit<ExchangeRelay, 'id'>),
  }));

  // Determine the last visible document for pagination.
  const lastVisible =
    relaySnapshot.docs.length > 0
      ? relaySnapshot.docs[relaySnapshot.docs.length - 1]
      : null;

  // Extract caseUids from the exchangeRelay documents.
  const caseUids = relays
    .map((relay) => relay.caseUid)
    .filter((uid) => uid); // remove falsy values

  let cases: Case[] = [];
  if (caseUids.length > 0) {
    // Note: Firestore "in" queries are limited to 10 items.
    // For more than 10 caseUids, you would need to batch these queries.
    const casesRef = collection(db, 'cases');
    const casesQuery = query(casesRef, where('__name__', 'in', caseUids));
    const casesSnapshot = await getDocs(casesQuery);
    cases = casesSnapshot.docs.map((doc) => ({
      uid: doc.id,
      ...(doc.data() as Omit<Case, 'uid'>),
    }));
  }

  const hasNextPage = cases.length === batchSize;

  return { relays, cases, lastVisible, hasNextPage };
};

/**
 * Custom hook that wraps the above function with React Query.
 *
 * @param carrierCode   The carrier code to filter exchange relays.
 * @param batchSize     The number of documents to fetch per page.
 * @param ninetyDaysAgo A timestamp or date value to filter recent records.
 * @param lastRelayDoc  A DocumentSnapshot to paginate (or null for the first page).
 */
export const useExchangeRelayAndCases = (
  carrierCode: string,
  batchSize: number,
  lastRelayDoc: DocumentSnapshot | null
) => {
  return useQuery(
    ['exchangeRelayAndCases', { carrierCode, batchSize, lastRelayDoc }],
    fetchExchangeRelayAndCases,
    {
      keepPreviousData: true,
    }
  );
};
