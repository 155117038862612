
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { Case } from '../models/Case/case';
import { useQuery } from 'react-query';
import { queryClient } from '../utils/queryClient';


const fetchCase = async (caseUid: string): Promise<Case | null> => {
  if (!caseUid) {
    throw new Error('No case UID provided');
  }
  const caseDocRef = doc(db, 'cases', caseUid);
  const caseDoc = await getDoc(caseDocRef);
  if (caseDoc.exists()) {
    return caseDoc.data();
  } else {
    throw new Error('No such document in cases!');
  }
};

export const useCase = (caseUid: string) => {
  return useQuery(
    ['case', caseUid],
    () => fetchCase(caseUid),
    {
      enabled: !!caseUid, // Only run if caseUid is provided
      staleTime: 4 * 60 * 60 * 1000, // Cache data for 4 hours
    }
  );
};

export const invalidateCaseCache = (uid: string): Promise<void> => {
  return queryClient.invalidateQueries(['case', uid]);
};
