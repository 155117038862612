import React, { SetStateAction, useEffect, useState } from "react";
import { carrierContacts } from "../../types/carrierContacts";
import { useForm } from "react-hook-form";
import { Case, CaseStatus } from "../../models/Case/case";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Carrier } from "../../models/carrier";
import { useAppSelector } from "../../hooks/redux-hooks";
import { selectAuth } from "../../redux/slices/authSlice";
import "./caseplacemententry.css";
import toast from "react-hot-toast";
import { useCase } from "../../services/caseService";

type CasePlacementEntryProps = {
  placed: boolean;
  caseUid: string;
  firstName: string;
  lastName: string;
  setModalOpen: (event: SetStateAction<boolean>) => void;
  onCaseStatusUpdate: (status: CaseStatus) => void;
};

const CasePlacementEntry: React.FC<CasePlacementEntryProps> = ({ placed, caseUid, firstName, lastName, setModalOpen, onCaseStatusUpdate }) => {
  const [note, setNote] = useState<string>('');
  const [selectedCarrier, setSelectedCarrier] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [carriers, setCarriers] = useState<Carrier[]>([]);

  const { userData } = useAppSelector(selectAuth);

  const { handleSubmit, setValue, register } = useForm<Case>();

  useEffect(() => {
    const fetchCarriers = () => {
      const sortedCarriers = [...carrierContacts]
        .filter(carrier => carrier.active)
        .sort((a, b) => a.name.localeCompare(b.name));
      // Assuming you have a setCarriers function defined somewhere
      setCarriers(sortedCarriers.filter(i => i.exchangePartner === userData?.exchangePartner));
    };
    fetchCarriers();
  }, [userData]);

  const { data: caseData } = useCase(caseUid);
  // Once case data is available, update form state
  useEffect(() => {
    if (caseData) {
      Object.keys(caseData).forEach(key => {
        setValue(key as keyof Case, caseData[key as keyof Case]);
      });
      setSelectedCarrier(caseData.placedCarrier ?? '');
      setNote(caseData.placedNote ?? '');
      console.log(caseData)
    }
  }, [caseData, setValue, setSelectedCarrier, setNote]);

  useEffect(() => {
    if (placed) {
      setIsFormValid(note.trim() !== '' && selectedCarrier.trim() !== '');
    } else {
      setIsFormValid(note.trim() !== '');
    }
  }, [note, selectedCarrier, placed]);

  const onSubmit = async (caseData: Case) => {
    try {
      if (note.length === 0) {
        toast.error("Note is required.");
        return;
      }

      if (placed && selectedCarrier.length === 0) {
        toast.error("Carrier selection is required");
        return;
      }

      if (!placed) {
        caseData.placedCarrier = '';
      }
      caseData.caseStatus =  placed ? 'Placed' : 'No Acceptable Offer';

      // Update Firestore
      const docRef = doc(db, "cases", caseUid);
      await updateDoc(docRef, caseData);
      toast.success("Status updated successfully!");
      setModalOpen(false);
      onCaseStatusUpdate(caseData.caseStatus);
    } catch (err: any) {
      toast.error("An upexpected error occurred.  Try again later");
    }
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleCarrierChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCarrier(e.target.value);
  };

  return (
    <>
      <div className="form-section">
        <h3>
          { placed ? 'Offer Placed' : 'No Acceptable Offer'} - { firstName } { lastName }
        </h3>
      </div>
      
      <form onSubmit={handleSubmit(onSubmit)} className="placed-form">
        { placed &&
          <div className="form-row">
            <div className="form-group">
              <select id="carrier" {...register("placedCarrier")} onChange={handleCarrierChange} >
                <option value="">Select Carrier</option>
                {carriers.map((carrierContact) => (
                  <option key={carrierContact.code} value={carrierContact.code}>
                    {carrierContact.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        }

        <div className="form-row">
          <div className="form-group-wide">
            <textarea id="placedNote" {...register("placedNote")} onChange={handleNoteChange}
              placeholder={ placed ? 'Enter placement details...' : 'Enter reason  for no acceptable offer...'}
            />
          </div>
        </div>

        <button type="submit" disabled={!isFormValid}>
          Submit
        </button>
      </form>
    </>
  );
};

export default CasePlacementEntry;
