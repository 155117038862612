import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { User } from '../models/User';
import { getEmailDomain } from '../utils/email';
import { getCachedData, setCachedData } from './cache.service';
import { useQuery, UseQueryOptions } from 'react-query';

const CACHE_DURATION = 4 * 60 * 60 * 1000; // 24 hours in milliseconds

export const fetchUserByEmail = async (
  email: string
): Promise<User | null> => {
  const cacheKey = `fetchUserByEmail_${email}`;
  const cacheddata = getCachedData<User>(cacheKey, CACHE_DURATION);
  if (cacheddata) {
    return cacheddata;
  }

  // Fetch from Firestore if no valid cache exists or forceRefresh is true
  const userCollectionRef = collection(db, 'users');
  const q = query(userCollectionRef, where('email', '==', email));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  const userDoc = querySnapshot.docs[0];
  const user = { uid: userDoc.id, ...userDoc.data() } as User;

  // Cache the result
  setCachedData(cacheKey, user);

  return user;
};

const fetchUsersByEmailDomain = async (email: string): Promise<User[]> => {
  const domain = getEmailDomain(email);
  if (!domain) {
    throw new Error("Invalid email provided.");
  }

  const userCollectionRef = collection(db, 'users');
  const q = query(userCollectionRef, where('emailDomain', '==', domain));
  const querySnapshot = await getDocs(q);

  const users: User[] = querySnapshot.docs.map((doc) => ({
    id: doc.id,querySnapshot,
    ...(doc.data() as Omit<User, 'id'>),
  }));

  // Sort users by lastName in ascending order.
  users.sort((a, b) => a.lastName.localeCompare(b.lastName));
  return users;
};

// Custom hook to cache this query using React Query.
export const useUsersByEmailDomain = (
  email: string | undefined,
  options?: UseQueryOptions<User[], Error>
) => {
  return useQuery<User[], Error>(
    ['usersByEmailDomain', email],
    () => {
      if (!email) throw new Error("Email is required");
      return fetchUsersByEmailDomain(email);
    },
    {
      // The query only runs if email is defined.
      enabled: !!email,
      // Cache the data for 4 hours
      staleTime: 4 * 60 * 60 * 1000,
      ...options,
    }
  );
};
